import styled from "styled-components";
import React, {useCallback} from "react";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  cursor: pointer;
`;

const Circle = styled.div<{size?: number, checked?: boolean, text?: string}>`
  display: inline-block;
  width: ${p => p.size || 16}px;
  height: ${p => p.size || 16}px;
  border-radius: 50%;
  border: 1px solid #272727;
  position: relative;
  
  &::after {
    position: absolute;
    left: 25%;
    top: 25%;
    content: ' ';
    width: 50%;
    height: 50%;
    background-color: #FFF;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.1s linear;
  }

  ${p => p.checked && `
    border: none;
    background-color: #2F99FB;
    
    &::after {
      transform: scale(1);
    }
  `};
`;

interface RadioButtonProps {
  size?: number;
  checked?: boolean;
  onChecked?: (checked: boolean) => void;
  children?: any;
}

function RadioButton({size, checked, onChecked, children}: RadioButtonProps) {
  const handleClick = useCallback(() => {
    onChecked && onChecked(!checked)
  }, [checked, onChecked]);

  return <Wrapper onClick={handleClick}>
    <Circle size={size} checked={checked}/>
    {children}
  </Wrapper>
}


export default RadioButton;