import styled from "styled-components";
import {useEffect, useMemo, useRef} from "react";

const CircleProgressWrapper = styled.div`{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 4px);
  height: calc(100% + 4px);

  .circle_progress_wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .circle_progress {
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
    will-change: contents;

    path {
      transition: fill 1s linear;  
    }
    
    .stroke {
      fill: #2295FF;
    }

    .bg {
      fill: #2295FF;
      fill-opacity: 0.7;
    }
  }
}`

interface CircleProgressProps {
  className?: string;
  value: number;
  max: number;
}

const TimerProgress = (
  {
    className,
    value,
    max,
  }: CircleProgressProps
) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const animFrameRef = useRef<number>();

  const size = useMemo(() => {
    return wrapperRef.current ? (wrapperRef.current.offsetWidth) : 0;
  }, [wrapperRef.current]);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    if (animFrameRef.current) {
      cancelAnimationFrame(animFrameRef.current);
    }

    const bgElem = wrapperRef.current.querySelector('.bg') as SVGPathElement | null;
    const strokeElem = wrapperRef.current.querySelector('.stroke') as SVGPathElement | null;

    if (bgElem && strokeElem) {
      if (value > max) {
        value = max;
      } else if (value < 0) {
        value = 0;
      }

      if (value <= 4) {
        bgElem.style.fill = '#FF2222'
        strokeElem.style.fill = '#FF2222'
      }

      let start: number;
      const progress = (timestamp: number) => {
        if (value === max) {
          const degree = value / (max) * 360;
          bgElem.setAttribute("d", describeArc(size, degree));
          strokeElem.setAttribute('d', describeArc(size, degree, 2));
        } else {
          if (!start) {
            start = timestamp;
          }

          const animatedValue = (value + 1) - ((timestamp - start) / 1000);
          if (animatedValue > value) {
            const degree = (animatedValue - 1) / (max - 1) * 360;
            bgElem.setAttribute("d", describeArc(size, degree));
            strokeElem.setAttribute('d', describeArc(size, degree, 2));
            animFrameRef.current = requestAnimationFrame(progress);
          }
        }
      }

      requestAnimationFrame(progress);
    }
  }, [value, max, size])

  return <CircleProgressWrapper ref={wrapperRef} className={className}>
    {
      size > 0 && (
        <svg className="circle_progress" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
          <path className="bg"/>
          <path className="stroke"/>
        </svg>
      )
    }
  </CircleProgressWrapper>
}

function describeArc(size: number, degree: number, stroke = 0) {
  const pos = size / 2
  const radius = stroke > 0 ? pos - stroke : 0;
  const spread = pos - radius;
  const innerStart = polarToCartesian(pos, radius, degree);
  const innerEnd = polarToCartesian(pos, radius, 0);
  const outerStart = polarToCartesian(pos, radius + spread, degree);
  const outerEnd = polarToCartesian(pos, radius + spread, 0);
  const largeArcFlag = degree <= 180 ? "0" : "1";

  return [
    "M", outerStart.x, outerStart.y,
    "A", radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
    "L", innerEnd.x, innerEnd.y,
    "A", radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
    "L", outerStart.x, outerStart.y, "Z"
  ].join(" ");
}

function polarToCartesian(pos: number, radius: number, deg: number) {
  const rad = (deg - 90) * Math.PI / 180.0;
  return {
    x: pos + (radius * Math.cos(rad)),
    y: pos + (radius * Math.sin(rad))
  };
}

export default TimerProgress;
