import styled from "styled-components";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 14px 0;

  .message {
    line-height: 150%;
  }

  .tip {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 8px;
    line-height: 1.5;
  }

  .border {
    width: 100%;
    min-height: 1px;
    background-color: #2D2D2D;
    margin: 16px 0;
  }
`;

const Hand = styled.div<{
  lack: boolean
}>`
  display: inline-flex;
  padding: 8px 10px;
  border-radius: 8px;
  background: rgba(255, 37, 37, 0.20);
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;

  ${p => !p.lack && `
    background: rgba(106, 255, 42, 0.2);
  `};

  img {
    margin-right: 4px;
  }

  span {
    margin-right: 2px;
    font-weight: 400;
  }
`;

const Settlement = styled.div`
  .stack {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .settle {
    font-size: 14px;
    margin-top: 16px;
  }

  span {
    opacity: 0.5;
    margin-right: 4px;

    &.diff {
      opacity: 1 !important;
      padding-left: 4px;

      &[data-loss="true"] {
        color: #FF2525;
      }

      &[data-loss="false"] {
        color: #37FF25;
      }
    }
  }
`;

function LeaveSettlement(
  {
    hands,
    stack,
    buyin,
    lossPercentage,
    settle,
    winAmount,
    maxHands,
    cuttingList,
    lack,
  }: {
    hands: number,
    stack: number,
    buyin: number,
    winAmount: number,
    lossPercentage: number,
    settle: number,
    maxHands: number,
    cuttingList: {handCount: number, lossPercentage: number}[],
    lack: boolean,
  }
) {
  const {t} = useTranslation();

  const lossAmount = useMemo(() => {
    return Math.floor(winAmount * (lossPercentage / 100));
  }, [winAmount, lossPercentage]);

  return <Wrapper>
    <Hand lack={lack}>
      {
        lack && (
          <img src="/images/ic_leave_warn.svg"/>
        )
      }
      <span>{t('핸드 수')}</span>{Number(hands||0).toLocaleString()}
    </Hand>
    <Settlement>
        <div className="stack">
            <span>{t('총 바이인')}</span>{Number(buyin|| 0).toLocaleString()}
        </div>
        <div className="stack">
            <span>{t('현재 보유 칩')}</span>{Number(stack|| 0).toLocaleString()}
        </div>
        <div className="stack">
            <span>{t('이익금')}</span>
            {
            winAmount > 0 ? <span className="diff" data-loss="false">
                +{Number(winAmount|| 0).toLocaleString()}
            </span> : 0
            }
        </div>
        <div className="settle">
            {t('정산 금액')} : {Number(settle|| 0).toLocaleString()}
            {
            lossAmount > 0 && (
                <span className="diff" data-loss="true">
                ({`-${Number(lossAmount || 0).toLocaleString()})`}
                </span>
            )
            }
        </div>
    </Settlement>
    <div className="border"/>
    <div className="message">
        {t("정산은 대기실에서 가능합니다.")}<br />
        {t("이용 중인 방에서 나가시겠습니까?")}
    </div>
  </Wrapper>
}

export default LeaveSettlement;