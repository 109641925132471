import styled from "styled-components";
import {useEffect, useState} from "react";

const ToggleSwitchWrapper = styled.div<{ isChecked: boolean }>`{
  width: 34px;
  height: 20px;
  position: relative;
  border-radius: 10px;
  background: #1F1F1F;
  > .toggle {
    position: absolute;
    top: 50%;
    ${p => p.isChecked ? `
      transform: translate(18px, -50%);
      background: #2F99FB; 
    `: `
      transform: translate(4px, -50%);
      background: #FFF;
      opacity: 0.5;
    `}
    transition: all 0.2s ease-in-out;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
}`;
interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}
const ToggleSwitch = ({checked, onChange}:ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(()=>{
    setIsChecked(checked)
  }, [checked])
  return <ToggleSwitchWrapper isChecked={isChecked}>
    <div className='toggle' onClick={() => {
      onChange(!isChecked);
      setIsChecked(!isChecked)
    }}/>
  </ToggleSwitchWrapper>
}

export default ToggleSwitch;
