import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {connectionData, requestCurrentRooms, requestDirectLinkLogin, requestJoinRoom, requestLeaveRoom, requestLogin, useSocketIsConnect} from "../api";
import {sha256} from "js-sha256";
import useDialog from "../hooks/useDialog";
import useQueryParams from "../hooks/useQueryParams";
import {useHistory} from "react-router-dom";
import useScreenOrientation from "../hooks/useScreenOrientation";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  text-align:center;
`;

function DirectLink() {
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const history = useHistory();
  const [conn] = useSocketIsConnect();
  const orientation = useScreenOrientation();

  useEffect(function(){
    let query = queryParams.get("q");
    let directRoomId = queryParams.get("roomId");
    if(conn.isConnected) {
      requestDirectLinkLogin(query).then(async(v)=>{
        if(v.login_token){
          if(directRoomId){
            let rooms = await requestCurrentRooms();
            if(rooms.list.length > 0){
              await requestLeaveRoom(rooms.list[0].roomId);
            }
            await requestJoinRoom(directRoomId);

            history.push('/game?id='+directRoomId)
          }else{
            history.replace("/lobby")
          }
        }else{
          alert(t("이미 만료된 입장입니다. 다시 입장해주세요."));
          // window.location.href = '/';
          return ;
        }
      })
    }
  },[conn.isConnected, conn.isLogin, queryParams])

  return  <Wrapper>
    {t('접속 중...')}
  </Wrapper>
}

export default DirectLink;