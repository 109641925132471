import styled from "styled-components";
import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 14px 0;

  .message {
    line-height: 150%;
  }

  .tip {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 8px;
    line-height: 1.5;
  }

  .border {
    width: 100%;
    min-height: 1px;
    background-color: #2D2D2D;
    margin: 16px 0;
  }
`;

const Hand = styled.div<{
  lack: boolean
}>`
  display: inline-flex;
  padding: 8px 10px;
  border-radius: 8px;
  background: rgba(255, 37, 37, 0.20);
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;

  ${p => !p.lack && `
    background: rgba(106, 255, 42, 0.2);
  `};

  img {
    margin-right: 4px;
  }

  span {
    margin-right: 2px;
    font-weight: 400;
  }
`;

const Settlement = styled.div`
  .stack {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .settle {
    font-size: 14px;
    margin-top: 16px;
  }

  span {
    opacity: 0.5;
    margin-right: 4px;

    &.diff {
      opacity: 1 !important;
      padding-left: 4px;

      &[data-loss="true"] {
        color: #FF2525;
      }

      &[data-loss="false"] {
        color: #37FF25;
      }
    }
  }
`;

function LeaveSettlement(
  {
    hands,
    stack,
    buyin,
    lossPercentage,
    settle,
    winAmount,
    maxHands,
    cuttingList,
    lack,
  }: {
    hands: number,
    stack: number,
    buyin: number,
    winAmount: number,
    lossPercentage: number,
    settle: number,
    maxHands: number,
    cuttingList: {handCount: number, lossPercentage: number}[],
    lack: boolean,
  }
) {
  const {t} = useTranslation();

  const lossAmount = useMemo(() => {
    return Math.floor(winAmount * (lossPercentage / 100));
  }, [winAmount, lossPercentage]);

  const message = useMemo(() => {
    if (lack) {
      return t('핸드 수 부족으로 인해 정산 시 이익금 중 {{lossPercentage}}%가 차감되어 정산됩니다.', {lossPercentage: lossPercentage});
    } else {
      return t('핸드 수를 충족하여 이익금 100% 정산 가능합니다.')
    }
  }, [lack, lossPercentage]);

  const cuttingListTexts = useMemo(() => {
    const texts = [];
    for(let i=0; i<cuttingList.length - 1; i++) {
      const h = [cuttingList[i].handCount, cuttingList[i+1].handCount];
      const p = cuttingList[i + 1].lossPercentage;
      texts.unshift(
        t('{{h0}} ~ {{h1}} 핸드 시 이익금 중 {{p}}% 차감', {h0: h[0], h1: h[1], p: p})
      )
    }

    return texts;
  }, [cuttingList]);

  return <Wrapper>
    <Hand lack={lack}>
      {
        lack && (
          <img src="/images/ic_leave_warn.svg"/>
        )
      }
      <span>{t('핸드 수')}</span>{Number(hands||0).toLocaleString()}
    </Hand>
    <div className="message">
      {message}
    </div>
    <div className="tip">
      {t('{{maxHands}} 핸드 초과 시 100% 정산 가능', {maxHands: maxHands})}<br/>
      {
        cuttingListTexts.map((x, i) => <React.Fragment key={i}>
          {x}<br/>
        </React.Fragment>)
      }
    </div>
    <div className="border"/>
    <Settlement>
      <div className="stack">
        <span>{t('총 바이인')}</span>{Number(buyin|| 0).toLocaleString()}
      </div>
      <div className="stack">
        <span>{t('현재 보유 칩')}</span>{Number(stack|| 0).toLocaleString()}
      </div>
      <div className="stack">
        <span>{t('이익금')}</span>
        {
          winAmount > 0 ? <span className="diff" data-loss="false">
            +{Number(winAmount|| 0).toLocaleString()}
          </span> : 0
        }
      </div>
      <div className="settle">
        {t('정산 금액')} : {Number(settle|| 0).toLocaleString()}
        {
          lossAmount > 0 && (
            <span className="diff" data-loss="true">
               ({`-${Number(lossAmount || 0).toLocaleString()})`}
            </span>
          )
        }
      </div>
    </Settlement>
  </Wrapper>
}

export default LeaveSettlement;