import {useEffect, useMemo, useState} from "react";
import {parseDatetime} from "../constants/moment";
import moment from "moment/moment";
import {calcPlayTime} from "../utils/tournament";

function deltaTime(diff: number){
  const sec = Math.floor(diff / 1000);
  const min = Math.floor(sec / 60);
  const hour = Math.floor(min / 60);
  return `${hour.toString().padStart(2,"0")}:${(min%60).toString().padStart(2,"0")}:${(sec%60).toString().padStart(2,"0")}`
}

function useTournamentTimer(
  {
    data,
    // format = "mm:ss"
  }: {
    data?: any;
    // format?: string
  }
) {
  const [timerText, setTimerText] = useState<string>('');
  const [isTournamentStarted, setTournamentStarted] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isClosed, setClosed] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    } else if (isClosed) {
      return;
    }

    function update() {
      const startedAt = parseDatetime(data.startedAt);
      const isStarted = moment().utc().isSameOrAfter(startedAt);

      let leftSec = 0;
      if (isStarted) {
        if (!isTournamentStarted) {
          setTournamentStarted(true);
          setIsOpen(true);
        }

        const {
          playTimeSeconds,
          restTimeSeconds
        } = data.timeStructure;
        const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds);

        let closeTime = 0;
        for(let i = 0; i < data.availableRegisterLevel-1 ; ++i) {
          closeTime += data.blindStructure[i][3]
        }

        leftSec = closeTime - playTime;
      } else {
        leftSec = Math.abs(moment().utc().unix() - moment.utc(data.startedAt).unix());
      }

      if( data.preRegisterBenefitTimeMinutes * 60 > leftSec){
        setIsOpen(true);
      }

      if (leftSec >= 0) {
        setTimerText(deltaTime(leftSec * 1000))
      } else {
        setClosed(true);
      }
    }

    update();
    const interval = setInterval(() => {
      update();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [data, "format", isTournamentStarted, isClosed, isOpen]);

  return {
    isTournamentStarted,
    timerText,
    isClosed,
    isOpen
  };
}

export default useTournamentTimer;