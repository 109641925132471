import {useRecoilState, useSetRecoilState} from "recoil";
import {emoticonBanState, myInfoState} from "./recoil/MyInfo";
import {useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {client, requestGetRoomGroupId, requestMyInfo, requestRegisterOrJoinTournament, requestToggleEmoticonBan, USER_TOKEN, useSocketIsConnect} from "./api";
import useDialog from "./hooks/useDialog";
import ModalContainer from "./components/common/ModalContainer";
import Dialog from "./components/Dialog";

import {setAckListener as setEndOfTournamentListener} from "./api/from_server_game_endOfTournament";
import {setAckListener as setMoveRoomClientListener} from "./api/from_server_game_moveRoomClient";
import {setAckListener as setEmoticonBanList} from "./api/from_server_game_emoticonBanList";
import {
  setAckListener as setMoveRoomWithTableBalancingListener
} from "./api/from_server_game_moveRoomWithTableBalancing";
import useQueryParams from "./hooks/useQueryParams";
import {DialogProps} from "./recoil/Dialog";
import {globalLoadingState} from "./recoil/Loading";
import Loading from "./components/common/Loading";
import {useSnackbar} from "notistack";
import {initSounds, Sounds} from "./utils/sound";
import {useTranslation} from "react-i18next";
import useBGM from "./hooks/useBGM";
import {useWakeLock} from "react-screen-wake-lock";

function Container({children}: {
  children: any
}) {
  const {t} = useTranslation();
  const [conn] = useSocketIsConnect();
  const history = useHistory();
  const [initialized, setInitialized] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = useQueryParams();
  const {enqueueSnackbar} = useSnackbar();
  const wakeLock = useWakeLock();

  const setMyInfo = useSetRecoilState(myInfoState);
  const setEmoticonBan = useSetRecoilState(emoticonBanState);
  const [globalLoading, setGlobalLoading] = useRecoilState(globalLoadingState);
  const {
    dialogs,
    openDialog,
    closeAllDialog
  } = useDialog();

  const isLobby = useMemo(() => {
    return (
      location.pathname.includes('lobby') ||
      location.pathname.includes('notice')
    );
  }, [location]);

  const isTournamentMenu = useMemo(() => {
    return (
      location.search.includes('tournament') || 
      location.pathname.includes('tournament')
    );
  }, [location]);

  // 로비 BGM 재생
  useBGM(
    isTournamentMenu ? Sounds.BGM_TOURNAMENT : Sounds.BGM_WAIT,
    initialized && (isLobby || isTournamentMenu)
  );

  // 화면 안 꺼지게
  useEffect(() => {
    const listener = () => {
      wakeLock.request();
      window.removeEventListener('click', listener);
    }
    window.addEventListener('click', listener);
  }, [wakeLock.request]);

  useEffect(() => {
    if (initialized) {
      setGlobalLoading(false);
    }
  }, [initialized]);

  useEffect(() => {
    if (typeof conn.isLogin === 'boolean') {
      (async () => {
        // await initSounds();

        if (!conn.isLogin) {
          const accessToken = queryParams.get('access_token');
          if (accessToken) {
            await new Promise<void>((resolve) => {
              client.emit("set-token", accessToken, (resp: any) => {
                if (resp.success == 1) {
                  localStorage.setItem(USER_TOKEN, accessToken);
                  window.location.reload();
                } else if (resp.success == 2) {
                  openDialog({
                    title: t("로그인 실패"),
                    text: t("동일한 계정으로 중복 접속을 할 수 없습니다.")
                  })
                }
                resolve();
              });
            });
          } else {
            // TODO: access token 없이 접속 했을 때 핸들링
          }
        } else {
          const v = await requestMyInfo()
          setMyInfo(v.info);
          requestToggleEmoticonBan(-1);
          setInitialized(true);
        }
      })();
    }
  }, [conn]);

  useEffect(function () {
    setEmoticonBanList((data:any) => {
      console.log("come emoticonBanList", data)
      setEmoticonBan(data.userIds);
    });

    setEndOfTournamentListener(async(data: any) => {
      closeAllDialog();

      openDialog({
        title: t('토너먼트 종료'),
        text: t('{{name}} 토너먼트에서 {{rank}}위를 달성하여 {{prize}}의 머니를 획득하셨습니다.', {
          name: data.name,
          rank: data.rank,
          prize: data.prize.toLocaleString()
        }),
        disableBackdropClick: true
      });

      const roomId = Number(queryParams.get('id') || 0);
      let r = await requestGetRoomGroupId(roomId);
      if( r.result == data.groupId ) {
        history.push("/");
      }
    });

    setMoveRoomWithTableBalancingListener(async (data: any) => {
      const roomId = Number(queryParams.get('id') || 0);
      if (location.pathname == '/game' && (roomId == 0 || roomId == data.prevRoomId)) {
        enqueueSnackbar(t("테이블 밸런싱 - 방을 이동했습니다."));
        let r = await requestRegisterOrJoinTournament(data.groupId);
        if (typeof (r.result) == "number") {
          history.push("/game?id=" + r.result);
        }
      }
    });

    setMoveRoomClientListener((data: {
      groupId: number,
      roomId: number
    }) => {
      const props: DialogProps = {
        title: t('안내'),
        text: t('토너먼트 시작 10분 전입니다 현 시간부터 토너먼트 입장 가능합니다.'),
        confirmText: t('확인'),
        disableBackdropClick: true,
      }

      const currentRoomId = Number(queryParams.get('id') || 0);
      if (!currentRoomId) {
        props.confirm = true;
        props.confirmText = t('이동');
        props.cancelText = t('닫기');
        props.onConfirm = async () => {
          let r = await requestRegisterOrJoinTournament(data.groupId);
          if (typeof (r.result) == "number") {
            history.push("/game?id=" + r.result);
          }
        }
      }

      openDialog(props);
    });

    return () => {
      setEmoticonBanList(null);
      setEndOfTournamentListener(null);
      setMoveRoomWithTableBalancingListener(null);
      setMoveRoomClientListener(null);
    }
  }, [queryParams]);

  const handleResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => handleResize)
    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  if (!initialized) {
    return null;
  }

  return <>
    {children}
    {
      dialogs.map((dialog) => (
        <ModalContainer key={dialog.id} show={dialog.visibility} onBackdropClick={dialog.onBackdropClick}>
          <Dialog dialog={dialog} onBackdropClick={dialog.onBackdropClick} />
        </ModalContainer>
      ))
    }
    <ModalContainer show={globalLoading} noSound>
      <Loading/>
    </ModalContainer>
  </>;
};

export default Container;
