import styled from "styled-components";
import {useCallback, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {playSFX, Sounds} from "../../utils/sound";
import { useRecoilState } from "recoil";
import { gameOptionState } from "../../recoil/GameOption";
import { fixedNumber } from "../../utils/common";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1px;
`;

const Gauge = styled.div`
  width: 78px;
  height: 170px;
  background-image: url(/images/bet_gauge_v_bg.png);
  position: relative;
`;

const Fill = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 0;
  will-change: height;
  background-image: url(/images/bet_gauge_v_fill.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.6));
  pointer-events: none;
  user-select: none;
`;

const Thumb = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  will-change: bottom;
  left: 0;
  text-align: center;
  transform: translateY(50%);

  > .chip {
    position: relative;
    width: 60px;
    height: 60px;
    background-image: url(/images/bet_gauge_v_thumb.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    cursor: pointer;

    > .label {
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translate(-50%, 100%);
      padding: 2px 8px;
      border-radius: 15px;
      background: rgba(24, 26, 29, 0.50);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12) inset;
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const Button = styled.div<{
  cancel?: boolean
}>`
  margin-bottom: -20px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 8px;
  cursor: pointer;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);

  ${p => p.cancel && `
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: radial-gradient(53.57% 53.57% at 50% 28.57%, rgba(255, 255, 255, 0.20) 0%, rgba(217, 217, 217, 0.00) 71%, rgba(217, 217, 217, 0.00) 100%);
  `};
`;

function BetGaugeVertical(
  {
    min,
    max,
    bb,
    onConfirm,
    onCancel
  }: {
    min: number;
    max: number;
    bb: number;
    onConfirm: (amount: number) => void;
    onCancel: () => void;
  }
) {
  const {t} = useTranslation();
  const ref = useRef<HTMLImageElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const prevState = useRef<any>(null);
  const lastPortion = useRef<number>(0);
  const [setting, setSetting] = useRecoilState(gameOptionState);

  useEffect(() => {
    if (!ref.current || !thumbRef.current) {
      return;
    }

    const gaugeHeight = ref.current.offsetHeight;

    const handleMouseEvent = (e: any) => {
      if (!thumbRef.current || !fillRef.current || !labelRef.current) {
        return;
      }

      e.stopPropagation();

      if (e.type === 'mousedown' || e.type === 'touchstart') {
        const thumbBottom = Number((thumbRef.current.style.bottom || '0').replace('px', ''));
        if (!prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          prevState.current = {
            thumbBottom: thumbBottom,
            originY: clientY
          };
        }
      } else if (e.type === 'mousemove' || e.type === 'touchmove') {
        if (prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          const dy = prevState.current.originY - clientY;
          const newBottom = Math.max(Math.min(prevState.current.thumbBottom + dy, gaugeHeight), 0);
          thumbRef.current.style.bottom = newBottom + 'px';
          fillRef.current.style.height = newBottom + 'px';

          const portion = Math.max(Math.min(newBottom / gaugeHeight, 1), 0);
          let amount = Math.floor(min + (max - min) * portion);
          const ceilAmount = Math.ceil(amount / 100) * 100;
          amount = ceilAmount > max ? max : ceilAmount;

          labelRef.current.setAttribute('data-amount', amount.toString());
          labelRef.current.innerText = setting.alwaysBB ? `${fixedNumber(amount / (bb||1), 2).toLocaleString()} BB`  : amount.toLocaleString();

          // 게이지 사운드 재생
          const portionPercent = Math.floor(portion * 100);
          if (lastPortion.current !== portionPercent && portionPercent % 10 === 0) {
            playSFX(Sounds.SFX_RAISE_GAGE, true);
          }
          lastPortion.current = portionPercent;
        }
      } else if (e.type === 'mouseup' || e.type === 'touchend') {
        if (prevState.current) {
          prevState.current = null;
        }
      }
    };

    thumbRef.current.addEventListener('mousedown', handleMouseEvent);
    thumbRef.current.addEventListener('touchstart', handleMouseEvent);
    window.addEventListener('mousemove', handleMouseEvent);
    window.addEventListener('touchmove', handleMouseEvent);
    window.addEventListener('mouseup', handleMouseEvent);
    window.addEventListener('touchend', handleMouseEvent);

    return () => {
      if (thumbRef.current) {
        thumbRef.current.removeEventListener('mousedown', handleMouseEvent);
        thumbRef.current.removeEventListener('touchstart', handleMouseEvent);
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('touchmove', handleMouseEvent);
        window.removeEventListener('mouseup', handleMouseEvent);
        window.removeEventListener('touchend', handleMouseEvent);
      }
    };
  }, [min, max, setting.alwaysBB, bb]);

  const handleConfirm = useCallback(() => {
    if (!labelRef.current) {
      return;
    }

    const amount = Number(labelRef.current.getAttribute('data-amount') || min);
    onConfirm && onConfirm(amount);
  }, [onConfirm, min]);

  return <Wrapper ref={ref}>
    <Button cancel onClick={onCancel}>{t('취소')}</Button>
    <Gauge>
      <Fill ref={fillRef}/>
      <Thumb ref={thumbRef}>
        <div className="chip">
          <div ref={labelRef} className="label">
            {setting.alwaysBB ?
              `${fixedNumber(min / (bb||1), 2).toLocaleString()} BB` :
              min.toLocaleString()
            }
          </div>
        </div>
      </Thumb>
    </Gauge>
    <Button onClick={handleConfirm}>{t('확인')}</Button>
  </Wrapper>;
}

export default BetGaugeVertical;
