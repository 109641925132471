import moment from "moment/moment";
import i18n from "./i18n";
import i18next from "i18next";

export const wait = async (ms: number) => {
    await new Promise<void>((r) => setTimeout(() => r(), ms));
}

export const convertMinutes = (time: number) => {
    if ((time % 60) === 0) {
        return moment(time * 1000).format(i18n.t("m분"))
    } else {
        return moment(time * 1000).format(i18n.t("m분 s초"))
    }
}


export const fixedNumber = (value: number, fixed: number) => {
    //fixed 자리수까지 소수점
    return Number(value.toFixed(fixed))
}

export const numCardsByRank = (rank: number): number =>  {
    if (rank === 1) {
        return 1;
    } else if (rank === 2) {
        return 2;
    } else if (rank === 3 || rank === 8) {
        return 4;
    } else if (rank === 4) {
        return 3;
    } else {
        return 5;
    }
}

export const toCardString = (value: string, suit: string) => {
    return value.replace('1', 'A') + suit;
}

export const toEasternNumeral = (n: number): string => {
    if (n <= 0) {
        return '0';
    }

    const chunks = [];
    const units = [i18n.t("조"), i18n.t("억"), i18n.t("만"), ""];

    let num = n;
    while (num > 0) {
        const mod = Math.floor(num % 10000);
        const modStr = mod.toLocaleString();
        num = Math.floor(num / 10000);
        if (mod === 0) {
            units.pop();
            continue;
        }

        const unit = units.pop();
        chunks.unshift(`${modStr}${unit}`);
    }

    return chunks.slice(0, 2).join(' ');
}
