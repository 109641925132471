import styled, {keyframes} from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {ceremonyCardsState, shiningCardsState} from "../../recoil/Game";
import {playSFX, Sounds, stopSound} from "../../utils/sound";
import {CARD_INFO} from "../../dataset";

const ScaleAnim = keyframes`
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
`;

const Wrapper = styled.div<{
  isOpenCard?: boolean;
}>`
  width: 42px;
  height: 56px;
  perspective: 1000px;
  display: inline-block;
  position: relative;

  &[data-ceremony="true"] {
    filter: brightness(0.5);
    
    &[data-zoomed="true"] {
      filter: none;
      box-shadow: 0px 2.2px 2.2px 0px rgba(0, 0, 0, 0.20), 0px 0px 6.6px 0px #FFD764;
    }
  }

  ${p => p.isOpenCard ? `
    top: -8px;
  `: `
    top: 0px;
  `}
  
  .open-icon {
    cursor:pointer;
    position: absolute;
    left:2px;
    right:2px;
    bottom:10px;
    /* ${p => p.isOpenCard ? `
      bottom:18px;
    `: `
      bottom:10px;
    `} */
    background-color: rgba(0,0,0,0.5);
    padding: 2px;
    border-radius: 5px;

    >div{
      font-size: 10px;
      text-align: center;
    }

    ${p => p.isOpenCard ? `
      opacity:0;
    `: `
      opacity:1;
    `}
  }
  &:hover {
    .open-icon {
      background-color: rgba(0,0,0,0.8);
    }
  }
`;

const Card = styled.div<{
  flipped?: boolean;
  isOpenCard?: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.5s linear;
  transform-style: preserve-3d;
  will-change: transform;
  cursor: pointer;

  ${p => p.flipped && `
    transform: rotateY(-180deg);
  `};
  ${p => p.isOpenCard ? `
    transition: all 0.1s linear;
    opacity: 1 !important;
  `: ``};

  > img {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotateY(-180deg);
      ${p => p.isOpenCard ? `
        transition: all 0.1s linear;
        opacity: 1 !important;
        top: 0px;
      `: ``};
    }
  }
`;

const ShineEffect = styled.img<{
  isOpenCard?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.3, 1.2);

  ${p => p.isOpenCard ? `
    top:-8px;
  `: `
    top:0;
  `}
`;

function PokerCard(
  {
    card,
    flip,
    delay = 0,
    onClick,
    isOpenCard,
    isShowOpenIcon,
    ...p
  }: {
    isOpenCard?: boolean,
    card?: number,
    flip?: boolean,
    delay?: number,
    isShowOpenIcon?: boolean,
    onClick?: ()=> void,
    [k: string]: any
  }
) {
  const gameOption = useRecoilValue(gameOptionState);
  const [flipped, setFlipped] = useState(delay === -1 && flip);
  const shiningCards = useRecoilValue(shiningCardsState);
  const ceremonyCards = useRecoilValue(ceremonyCardsState);
  const [shine, setShine] = useState<boolean>(false);

  const isZoomed = useMemo<boolean>(() => {
    return card !== undefined && ceremonyCards.includes(CARD_INFO[card])
  }, [card, ceremonyCards]);

  useEffect(() => {
    if (card !== undefined && shiningCards.includes(CARD_INFO[card])) {
      setTimeout(() => {
        playSFX(Sounds.SFX_CARD_PREMIUM)
        setShine(true);

        setTimeout(() => {
          stopSound(Sounds.SFX_CARD_PREMIUM)
          setShine(false);
        }, 3000);
      }, 2000);
    }
  }, [card, shiningCards]);

  useEffect(() => {
    let timer: NodeJS.Timer
    if (flip) {
      timer = setTimeout(() => {
        setFlipped(true);
      }, delay || 10);
    } else {
      setFlipped(false);
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [flip, delay]);

  return <Wrapper {...p} data-ceremony={ceremonyCards.length > 0} data-zoomed={isZoomed} isOpenCard={isOpenCard} onClick={() => {
    if (onClick) {
      onClick();
    }
  }}>
    <Card flipped={flipped} isOpenCard={isOpenCard}>
      <img src={`/images/card_back.svg`}/>
      {
        card !== undefined ?
          (
            (card === -1) ? <img src={`/images/card_back.svg`}/> :
              <img alt='card' src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
          ) :
          <img alt='card' src={`/images/card_back.svg`}/>
      }
    </Card>
    {(isShowOpenIcon || isOpenCard) && <div className='open-icon'>
      <div>Open</div>
    </div> }
    {
      shine && (
        <ShineEffect isOpenCard={isOpenCard} src="/images/effect/made.png" />
      )
    }
  </Wrapper>;
}

export default PokerCard;
