import styled from "styled-components";

const Wrapper = styled.div<{
  rate: number
}>`
  width: 100%;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.40);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;

  ${p => p.rate >= 0 && p.rate <= 34 && `
    background: linear-gradient(90deg, rgba(85, 224, 36, 0.00) 0%, #55E024 51.56%, rgba(85, 224, 36, 0.00) 100%);
  `};

  ${p => p.rate >= 35 && p.rate <= 67 && `
    background: linear-gradient(90deg, rgba(70, 155, 255, 0.00) 0%, #469BFF 51.56%, rgba(70, 155, 255, 0.00) 100%);
  `};

  ${p => p.rate >= 68 && p.rate <= 100 && `
    background: linear-gradient(90deg, rgba(245, 142, 20, 0.00) 0%, #F58E14 51.56%, rgba(245, 142, 20, 0.00) 100%);
  `};
`;

function WinningRate({rate, ...p}: {
  rate?: number
}) {
  if (rate === undefined) {
    return null;
  }

  return <Wrapper rate={rate} {...p}>
    {rate}%
  </Wrapper>
}

export default WinningRate;