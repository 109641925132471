import {useHistory, useParams} from "react-router-dom";
import TournamentDetail from "../components/lobby/TournamentDetail";
import {useEffect} from "react";
import useDialog from "../hooks/useDialog";
import {useTranslation} from "react-i18next";

function Tournament() {
  const history = useHistory();
  const {groupId} = useParams<{
    groupId: string
  }>();
  const {t} = useTranslation();
  const {openDialog} = useDialog();

  useEffect(() => {
    if (!groupId) {
      openDialog({
        title: t('오류'),
        text: t('잘못된 접근입니다.'),
        onConfirm: () => {
          history.goBack();
        }
      });

      return;
    }
  }, [groupId])

  if (!groupId) {
    return null;
  }

  return <TournamentDetail groupId={Number(groupId)} />
}

export default Tournament;