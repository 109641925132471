import styled from "styled-components";
import Emoticon from "./Emoticon";
import {Swiper, SwiperSlide} from "swiper/react";
import {useLayoutEffect, useMemo, useState} from "react";
import {Pagination} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #101012;
  padding: 12px;
  color: #FFF;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;

    > div:first-child {
      font-size: 14px;
      font-weight: bold;
    }

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .swiper {
    width: 100%;

    --swiper-pagination-bullet-size: 4px;
    --swiper-pagination-color: #D9D9D9;
    --swiper-pagination-bullet-inactive-color: #D9D9D9;
    --swiper-pagination-bullet-inactive-opacity: 0.5;
    --swiper-pagination-bullet-horizontal-gap: 3px;

    .swiper-wrapper {
      max-height: 104px;
    }

    .swiper-pagination {
      position: initial;
    }
  }
`;

const EmojiGrid = styled.div`
  height: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, 48px);
  grid-auto-flow: row;
  grid-gap: 8px 9px;
  justify-content: center;

  > .item {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

function EmoticonSelector({onClose}: {
  onClose: (emoticonId?: number) => void
}) {
  const [columns, setColumns] = useState<number>(1);
  const pages = useMemo(() => {
    const ret: number[][] = [];
    for (let i = 1; i <= 16; i++) {
      const page = Math.floor((i - 1) / (columns * 2));
      if (!ret[page]) {
        ret[page] = [];
      }

      ret[page].push(i);
    }
    return ret;
  }, [columns]);

  useLayoutEffect(() => {
    const handleResize = () => {
      const itemWidth = 48;
      const gap = 8;
      const pad = 12;

      setColumns(Math.floor((window.innerWidth - pad * 2 + 1) / (itemWidth + gap)));
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return <Wrapper>
    <div className="header">
      <div onClick={() => onClose()}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M4.99685 11.6634C4.8146 11.8456 4.51912 11.8456 4.33688 11.6634C4.15463 11.4811 4.15463 11.1857 4.33688 11.0034L7.34023 8.00007L4.33688 4.99672C4.15463 4.81448 4.15463 4.519 4.33688 4.33676C4.51912 4.15451 4.8146 4.15451 4.99685 4.33676L8.0002 7.34011L11.0035 4.33676C11.1858 4.15451 11.4813 4.15451 11.6635 4.33676C11.8458 4.519 11.8458 4.81448 11.6635 4.99672L8.66016 8.00007L11.6635 11.0034C11.8458 11.1857 11.8458 11.4811 11.6635 11.6634C11.4813 11.8456 11.1858 11.8456 11.0035 11.6634L8.0002 8.66004L4.99685 11.6634Z"
                fill="white"/>
        </svg>
      </div>
    </div>
    <Swiper pagination={true} modules={[Pagination]}>
      {
        pages.map((emoticons, i) => (
          <SwiperSlide key={i}>
            <EmojiGrid>
              {
                emoticons.map((id) => (
                  <div key={'emoticon:' + id} className="item" onClick={() => onClose(id)}>
                    <Emoticon id={id} size={48}/>
                  </div>
                ))
              }
            </EmojiGrid>
          </SwiperSlide>
        ))
      }
    </Swiper>
  </Wrapper>
}

export default EmoticonSelector;
