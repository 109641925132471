import {useRecoilValue} from "recoil";
import {gameOptionState} from "../recoil/GameOption";
import {useEffect} from "react";
import {playBGM, stopBGM} from "../utils/sound";

function useBGM(sound: string, playing: boolean) {
  const gameOption = useRecoilValue(gameOptionState)

  useEffect(() => {
    console.log(sound, gameOption.bgmMuted , playing)
    if (!gameOption.bgmMuted && playing) {
      playBGM(sound);
    } else {
      stopBGM();
    }
  }, [sound, playing, gameOption.bgmMuted]);
}

export default useBGM;