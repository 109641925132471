import "./overrides";
import React, {useLayoutEffect} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {SnackbarProvider} from 'notistack'
import {RecoilRoot} from "recoil";
import theme from './theme';
import Container from './Container';
import RejoinCheck from "./components/RejoinCheck";
import Lobby from "./pages/Lobby";
import Game from "./pages/Game";
import Tournament from "./pages/Tournament";
import DirectLink from "./pages/DirectLink";
import ArticleViewer from "./pages/ArticleViewer";

const App: React.FC = () => {
  return (<ThemeProvider theme={theme}>
    <BrowserRouter>
      <Route path={'/link'} exact>
        <DirectLink />
      </Route>

      <RecoilRoot>
        <RejoinCheck/>
        <SnackbarProvider className="snackbar" anchorOrigin={{horizontal: "center", vertical: "top"}}>
          <Container>
            <Switch>
              <Redirect path="/" exact to={"/lobby"}/>
              <Route path={'/lobby'} exact>
                <Lobby/>
              </Route>
              <Route path={'/tournament/:groupId'} exact>
                <Tournament/>
              </Route>
              <Route path={'/game'} exact>
                <Game/>
              </Route>
              <Route path={'/notice/:noticeId'} exact>
                <ArticleViewer />
              </Route>
            </Switch>
          </Container>

        </SnackbarProvider>
      </RecoilRoot>
    </BrowserRouter>
  </ThemeProvider>);
};
export default App;