import styled from "styled-components";
import React, {useCallback, useEffect, useState} from "react";
import {MEDIA_DESKTOP, MIN_WIDTH_DESKTOP} from "../hooks/useScreenOrientation";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useQueryParams from "../hooks/useQueryParams";
import {requestGetNoticeDetail} from "../api";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  text-align: center;

  @media ${MEDIA_DESKTOP} {
    height: 72px;
  }

  > img.back {
    padding: 14px 20px;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      padding: 24px;
    }
  }

  > .title {
    flex: 1;
    padding: 4px 55px 4px 0;
    color: #FFF;
    font-size: 12px;
    font-weight: 500;

    @media ${MEDIA_DESKTOP} {
      font-size: 14px
    }
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  max-width: 600px;
  margin: 0 auto;
  
  .banner {
    width: 100%;
  }
  
  .text {
    padding: 24px 20px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.2px;
  }
`;

function ArticleViewer() {
  const {t} = useTranslation();
  const history = useHistory();
  const {noticeId} = useParams<{
    noticeId: string
  }>();

  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    if (noticeId && Number(noticeId) > 0) {
      requestGetNoticeDetail(Number(noticeId)).then(({info}) => {
        if (info) {
          setTitle(info.title);
          setImage(info.image);
          setText(info.text);
        }
      });

      const r = JSON.parse(localStorage.getItem('notices') || '[]');
      r.push(Number(noticeId));
      localStorage.setItem('notices', JSON.stringify(Array.from(new Set(r))));
    }
  }, [noticeId]);

  const handleBack = useCallback(() => {
    history.goBack();
  }, []);

  return <Wrapper>
    <Header>
      <img className="back" src="/images/ic_header_back.svg" onClick={handleBack}/>
      <div className="title">{title}</div>
    </Header>
    <Content>
      <img className="banner" src={image} />
      <div className="text" dangerouslySetInnerHTML={{__html: text}}></div>
    </Content>
  </Wrapper>
}

export default ArticleViewer