import {BET_TYPE, GamePlayer, RoomInfo} from "../../dataset";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import InGameButton from "../common/InGameButton";
import BetGaugeVertical from "./BetGaugeVertical";
import styled from "styled-components";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import BetGaugeHorizontal from "./BetGaugeHorizontal";
import Flex from "../common/Flex";
import {useTranslation} from "react-i18next";
import { useRecoilState } from "recoil";
import { gameOptionState } from "../../recoil/GameOption";
import { fixedNumber } from "../../utils/common";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: center;
  padding: 20px 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  transform-origin: right bottom;

  @media ${MEDIA_DESKTOP} {
    width: auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: center;
  
  > * {
    min-width: 106px;
    max-width: 106px;
  }

  @media ${MEDIA_DESKTOP} {
    gap: 8px;

    > * {
      min-width: initial;
      max-width: initial;
      flex: 1;
    }
  }

`;

const BetGaugeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 75px;
  background: linear-gradient(180deg, rgba(16, 16, 18, 0.00) 0%, #101012 100%);
  z-index: 5;
`;

const RaiseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

const WideRaiseWrapper = styled.div`
  position: relative;
  width: 580px;
  height: 44px;
  display: flex;
  gap: 4px;
  background: rgba(24, 26, 29, 0.5);
  border-radius: 12px;
  padding: 4px;
  color: #FFF;

  > .button {
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid #242424;
    background: radial-gradient(102.77% 100% at 50% 0%, rgba(168, 168, 168, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), #1F1F1F;
    box-shadow: 0px 2px 4px 0px rgba(16, 16, 18, 0.20);
    cursor: pointer;

    &[data-disabled="true"] {
      user-select: none;
      pointer-events: none;
      opacity: 0.4;
      cursor: initial;
    }
  }

  > input {
    width: 120px;
    border-radius: 8px;
    border: 1px solid #8D8D8D;
    background: #1F1F1F;
    outline: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    padding: 12px 11px;

    &:focus {
      outline: none;
    }
  }
  .BBB{
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: bold;
  }
`;

const OpenGaugeButton = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(/images/btn_open_gauge.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: drop-shadow(0 2px 2px rgba(16, 16, 18, 0.20));
  position: absolute;
  left: -50%;
  bottom: 20px;
  transform: translate(50%, -100%);
  
  &:hover {
    filter: brightness(1.5);
  }

  &:active {
    filter: brightness(0.7);
  }
`;

function ActionButtons(
  {
    onClickTryBet,
    legalActs,
    room,
    myInfo,
    totalPot,
    bb,
  }: {
    onClickTryBet: Function,
    legalActs: string[],
    room: RoomInfo,
    myInfo: GamePlayer,
    totalPot: number,
    bb:number
  }
): JSX.Element {
  const {t} = useTranslation();
  const orientation = useScreenOrientation();
  const [showGauge, setShowGauge] = useState<boolean>(false);
  const [raiseAmount, setRaiseAmount] = useState<number>(0);
  const [setting, setSetting] = useRecoilState(gameOptionState);

  const wrapperTransformRef = useRef<string>('');

  const raiseAmountText = useMemo<string>(() => {
    return raiseAmount.toLocaleString();
  }, [raiseAmount]);

  const currentBet = useMemo<number>(() => {
    return room?.currentBet ?? 0;
  }, [room?.currentBet]);

  const [minRaise, stackSize] = useMemo<[number, number]>(() => {
    const ret: [number, number] = [room.minRaise ?? 0, myInfo?.stackSize ?? 0]
    if (ret[0] > ret[1]) {
      ret[0] = ret[1];
    }
    return ret;
  }, [currentBet, room?.minRaise, myInfo?.stackSize]);

  const myBet = useMemo(() => {
    return myInfo?.bet ?? 0;
  }, [myInfo?.bet]);

  const callAmount = useMemo<number>(() => {
    const amt = currentBet - myBet
    return amt > 0 ? amt : 0;
  }, [currentBet, myBet]);

  const callText = useMemo<string>(() => {
    return callAmount >= stackSize ? t('올인') : t('콜');
  }, [callAmount, stackSize]);

  const raiseButtons = useMemo(() => {
    let buttons: {
      label: string;
      action: BET_TYPE,
      amount: number;
      disabled: boolean
    }[] = [];
    if (!stackSize) {
      return buttons;
    }

    const action = legalActs.includes('bet') ? BET_TYPE.BET : BET_TYPE.RAISE;
    for (let p of [0.25, 0.5, 1]) {
      const amount = Math.ceil(((totalPot + callAmount) * p + currentBet) / 100) * 100;

      buttons.push({
        label: (p * 100) + '%',
        action: action,
        amount: amount,
        disabled: stackSize < amount || (amount + myBet) < currentBet + minRaise
      });
    }

    // 모바일에서 가장 높은 비율의 버튼이 비활성화 되었다면 전체 삭제
    if (orientation === 'portrait' && buttons.length > 0 && buttons[buttons.length - 1].disabled) {
      buttons = [];
    }

    buttons.push({
      label: 'MAX',
      action: BET_TYPE.ALLIN,
      amount: stackSize,
      disabled: false
    });

    return buttons;
  }, [orientation, totalPot, currentBet, myBet, callAmount, minRaise, stackSize, legalActs]);

  const handleChange = useCallback((v: number) => {
    const newValue = Number(v.toString().replace(/[^0-9]/g, ''));
    if (newValue > stackSize) {
      setRaiseAmount(myInfo.stackSize);
    } else {
      setRaiseAmount(Number(v));
    }
  }, [stackSize]);

  const handleInputBB = useCallback((e: any) => {
    if (e.nativeEvent?.data && (/[^0-9]/g).test(e.nativeEvent.data)) {
      e.preventDefault();
      return;
    }

    const v = (e.target?.value ?? 0).toString().replace(/[^0-9]/g, '')
    handleChange(Number(v) * bb)
  }, [handleChange, bb])

  const handleInput = useCallback((e: any) => {
    if (e.nativeEvent?.data && (/[^0-9]/g).test(e.nativeEvent.data)) {
      e.preventDefault();
      return;
    }

    const v = (e.target?.value ?? 0).toString().replace(/[^0-9]/g, '')
    handleChange(Number(v))
  }, [handleChange])

  useEffect(() => {
    setRaiseAmount(Math.min(currentBet + minRaise - myBet, stackSize));
  }, [currentBet, minRaise, myBet, stackSize]);

  useEffect(() => {
    const wrapper = document.querySelector('.ingame-button-wrapper') as HTMLElement;
    if (!wrapper) {
      return;
    }

    if (showGauge) {
      wrapperTransformRef.current = wrapper.style.transform;
      wrapper.style.transform = 'none';
      wrapper.style.zIndex = '10';
    }

    return () => {
      if (wrapperTransformRef.current) {
        wrapper.style.transform = wrapperTransformRef.current;
        wrapperTransformRef.current = ''
        wrapper.style.zIndex = '';
      }
    }
  }, [showGauge]);

  if (orientation === 'landscape') {
    return <Wrapper>
      {
        (legalActs.includes('bet') || legalActs.includes('raise')) && (
          <WideRaiseWrapper>
            {
              raiseButtons.map((
                {
                  label,
                  amount,
                  disabled
                }, i
              ) => (
                <div key={i} className="button" data-disabled={disabled}
                     onClick={() => setRaiseAmount(amount)}>{label}</div>
              ))
            }
            {
              setting.alwaysBB ?
                <><input type='text' value={`${fixedNumber(raiseAmount / (bb||1), 2).toLocaleString()}`} onInput={handleInputBB}/>
                <div className="BBB">BB</div></>:
                <input type='text' value={raiseAmountText} onInput={handleInput}/>
            } 
            <Flex>
              <BetGaugeHorizontal
                min={Math.min(currentBet + minRaise - myBet, stackSize)}
                max={stackSize}
                value={raiseAmount}
                onChange={handleChange}
              />
            </Flex>
          </WideRaiseWrapper>
        )
      }
      <ButtonWrapper>
        <InGameButton bb={bb} onClick={() => onClickTryBet(BET_TYPE.FOLD)}>{t('폴드')}</InGameButton>
        {
          legalActs.includes('check') && (
            <InGameButton bb={bb} onClick={() => onClickTryBet(BET_TYPE.CHECK)}>{t('체크')}</InGameButton>
          )
        }
        {
          legalActs.includes('call') && (
            <InGameButton callAmount={Math.min(stackSize, callAmount)} isAllIn={callAmount >= stackSize} bb={bb}
                          onClick={() => onClickTryBet(BET_TYPE.CALL)}>{callText}</InGameButton>
          )
        }
        {
          (legalActs.includes('bet')) && (
            <InGameButton bb={bb} onClick={() => onClickTryBet(BET_TYPE.BET, raiseAmount)}>{t('레이즈')}</InGameButton>
          )
        }
        {
          (legalActs.includes('raise')) && (
            <InGameButton bb={bb} onClick={() => onClickTryBet(BET_TYPE.RAISE, raiseAmount)}>{t('레이즈')}</InGameButton>
          )
        }
      </ButtonWrapper>
    </Wrapper>;
  } else {
    if (showGauge) {
      return <BetGaugeWrapper>
        <BetGaugeVertical
          min={Math.min(currentBet + minRaise - myBet, stackSize)}
          max={stackSize}
          onConfirm={(amount) => onClickTryBet(BET_TYPE.RAISE, amount)}
          onCancel={() => setShowGauge(false)}
          bb={bb}
        />
      </BetGaugeWrapper>;
    }

    return <Wrapper>
      <ButtonWrapper>
        <InGameButton bb={bb} onClick={() => onClickTryBet(BET_TYPE.FOLD, 0, "ActionButtons:7")}>{t('폴드')}</InGameButton>
        {
          legalActs.includes("check") && (
            <InGameButton bb={bb} onClick={() => onClickTryBet(BET_TYPE.CHECK, 0, "ActionButtons:8")}>{t('체크')}</InGameButton>
          )
        }
        {
          legalActs.includes("call") && (
            <InGameButton callAmount={Math.min(callAmount, stackSize)} isAllIn={callAmount >= stackSize} bb={bb}
                          onClick={() => onClickTryBet(BET_TYPE.CALL, 0, "ActionButtons:9")}>{callText}</InGameButton>
          )
        }
        {
          (legalActs.includes("raise") || legalActs.includes("bet")) && (
            <RaiseWrapper>
              <OpenGaugeButton className="open-gauge" onClick={() => setShowGauge(true)}/>
              {
                raiseButtons.map((
                  {
                    label,
                    action,
                    amount,
                    disabled
                  }, i
                ) => (
                  <InGameButton
                    key={i}
                    bb={bb}
                    raise
                    subTitle={label}
                    disabled={disabled}
                    onClick={() => {onClickTryBet(action, amount)}}
                  >
                    {setting.alwaysBB ?
                      `${fixedNumber(amount / (bb||1), 2).toLocaleString()} BB` :
                      amount.toLocaleString()
                    }
                  </InGameButton>
                )).reverse()
              }
            </RaiseWrapper>
          )
        }
      </ButtonWrapper>
    </Wrapper>;
  }
}

export default ActionButtons;
