/* eslint-disable */
//auto gen
import {
    BET_TYPE,
    GAME_STATUS,
    MyPlayStatus,
    ROOM_JOIN_STATUS,
    ROOM_STATUS,
    ROOM_TYPE,
    RoomInfo,
    RoomStatus,
    winnerModel,
    RAKE_TYPE,
    GroupInfo,
    emitAfterBetModel,
    requestBetModel,
    handHistoryModel,
    GamePlayer,
    userProfileModel,
    tournamentRankingModel,
    winnerListModel,
    CreateRingRoomInterface, CreateTournamentRoomInterface, SimpleTournamentUser, NoticeInfo, NoticeSimpleInfo
} from '../dataset/common'
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: {}
}
type StoreFunc = (data: {}) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(opt?:SockClientOpt) : Promise<{}>{
  return new Promise((resolve, reject) => {
    client.emit("game:isServerLive",  async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("game:isServerLive", (data:any)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (opt?:SockClientOpt) : [{} | null, Function] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([]);
  const [Data, setData] = useState<{} | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: {})=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[]);

  useEffect(() => {
    const handleChange = (data: {})=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[]);

  return [Data, () => request(opt)]

}
