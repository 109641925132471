import {useCallback, useRef, useState} from "react";
import produce from 'immer';
import {TexasHoldem} from "poker-odds-calc";
import {CARD_INFO} from "../dataset";

// @ts-ignore
window.TexasHoldem = TexasHoldem

function useWinningRate() {
  const calculator = useRef<TexasHoldem>(new TexasHoldem());

  const [winningRates, setWinningRates] = useState<{
    userId: number,
    rate?: number
  }[]>([]);

  const addPlayer = useCallback((userId: number, cards: number[]) => {
    if (!calculator.current) {
      return;
    }

    const hand = cards.map(x => CARD_INFO[x]);
    calculator.current.addPlayer([hand[0], hand[1]]);
    setWinningRates(produce(d => {
      if (d) {
        d.push({
          userId: userId,
          rate: undefined
        });
      }
    }))
  }, []);

  const setCommunityCard = useCallback(async (cards: number[]) => {
    if (!calculator.current || cards.length < 3) {
      return;
    }

    return new Promise<void>((r) => {
      const communityCards = cards.map(x => CARD_INFO[x]);
      calculator.current.boardAction((board: any) => {
        const currentCards = board.getCards();

        if (communityCards.length >= 3 && currentCards.length === 0) {
          board.setFlop(communityCards.slice(0, 3));
        }
        if (communityCards.length >= 4 && currentCards.length === 3) {
          board.setTurn(communityCards[3]);
        }
        if (communityCards.length === 5 && currentCards.length === 4) {
          board.setRiver(communityCards[4]);
        }

        if (communityCards.length === board.getCards().length) {
          r();
        }
      });
    })
  }, []);

  const resetWinningRates = useCallback(() => {
    calculator.current = new TexasHoldem();
    setWinningRates([]);
  }, []);

  const updateWinningRate = useCallback(() => {
    if (!calculator.current) {
      return;
    } else if (calculator.current.getPlayersInHand().length < 2) {
      return;
    }

    if (calculator.current.getLimit() !== 10000) {
      calculator.current.limit(10000);
    }

    const result = calculator.current.calculate();
    const players = result.getPlayers();

    const scalars: number[] = [];
    let denom = 0;
    for (let player of players) {
      const win = player.getWinsPercentage();
      const tie = player.getTiesPercentage();
      denom = denom + win + tie;
      scalars.push(win + tie);
    }

    setWinningRates(produce(d => {
      if (d && scalars.length === d.length) {
        for (let i = 0; i < d.length; i++) {
          const n = Math.min(scalars[i] / denom * 100, 100)
          d[i].rate = Number(n.toFixed(2));
        }
      }
    }));
  }, []);

  return {
    winningRates,
    resetWinningRates,
    addPlayer,
    setCommunityCard,
    updateWinningRate,
  }
}

export default useWinningRate;