import {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import produce from 'immer';
import Modal from "./common/Modal";
import {requestGetUserProfile, requestModifyUserProfile, requestMyInfo, requestReport, requestToggleEmoticonBan} from "../api";
import {userProfileModel} from "../dataset";
import useDialog from "../hooks/useDialog";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {emoticonBanState, myInfoState} from "../recoil/MyInfo";
import numbro from "numbro";
import {globalLoadingState} from "../recoil/Loading";
import {useTranslation} from "react-i18next";
import ModalContainer from "./common/ModalContainer";


const Content = styled.div`
  padding: 8px;
  color: #FFF;
`;

const Border = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: #2D2D2D;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  > .image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }

  > .info {
    flex: 1;

    > .name {
      font-size: 16px;
      font-weight: 600;
      display: flex;
    }

    > .hand {
      font-size: 12px;
      margin-top: 8px;

      > span {
        opacity: 0.5;
        margin-right: 4px;
      }

    }
  }
`;

const ModifyButton = styled.div`
  width: 66px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
  background: #1F1F1F;
`;

const ReportButton = styled(ModifyButton)`
  color: #FF2525;
  border: 1px solid #FF2525;
  background: transparent;
`;

const Stats = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  position: relative;

  .help {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 12px;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
  
  > .grid {
    display: grid;
    grid-template-columns: repeat(3, 85px);
    grid-auto-rows: auto;
    grid-gap: 20px 8px;
    padding: 16px 4px;
  }

  > .caption {
    opacity: 0.5;
  }
`;

const StatItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  > div {
    &:first-child {
      font-size: 12px;
      opacity: 0.5;
    }
  }
`;

const StatGauge = styled.div<{
  percentage: string
}>`
  width: 52px;
  height: 4px;
  background: #2D2D2D;
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: ${p => p.percentage}%;
    height: 100%;
    border-radius: 2px;
    background: #2F99FB;
    content: ' ';
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 11px;

  > div {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    /* padding: 16px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    cursor: pointer;

    &:nth-child(1) {
      background: #2D2D2D;
    }

    &:nth-child(2) {
      background: var(--Primary-blue, #2F99FB);
    }
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  margin-top: 8px;
`;

const NicknameForm = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
  padding: 10px 0;

  > div {
    &:first-child {
      font-size: 12px;
      opacity: 0.5;
      margin-bottom: 8px;
    }

    &:last-child {
      display: flex;

      > input {
        flex: 1;
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        background: transparent;
        border: none;
        outline: none;
      }

      > .limit {
        font-size: 12px;
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }


`;

const SelectImage = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  max-height: 60vh;
  overflow: scroll;

  > div {
    &:first-child {
      font-size: 12px;
      font-weight: 500;
      opacity: 0.5;
    }
  }

  > .grid {
    display: grid;
    grid-template-columns: repeat(4, 64px);
    grid-auto-rows: auto;
    grid-gap: 8px;
    padding: 16px 0;

    > .profile-image-wrapper {
      position: relative;
      width: 64px;
      height: 64px;

      > .profile-image {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        z-index: 1;
        //border: 2px solid transparent;
      }

      > .selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 50%;
        height: 100%;
        z-index: 5;
        border: 2px solid #2F99FB;
        background: rgba(16, 16, 18, 0.50);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const Help = styled.div`
  width: 239px;
  padding: 30px 16px;
  background: #17171B;
  border-radius: 12px;
  color: #FFF;
  font-weight: 500;
  letter-spacing: -0.2px;
  
  .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .desc {
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 12px;
  }
`;

const RebuyinAmount = styled.div`
  font-size: 13px;
  /* font-weight: 500; */
  color: #eaff00;
  margin-bottom: 4px;

  >span{
    margin-left: 5px;
    font-size: 12px;
    color: white;
  }
`

const EmoticonToggle = styled.div<{toggle:boolean}>`
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;

  img{
    width: 20px;
  }

  > div{
    &:first-child{
      display: ${p => p.toggle ? 'none' : 'block'};
    }
    &:last-child{
      display: ${p => p.toggle ? 'block' : 'none'};
    }
  }
`

export type ModifyType = 'nickname' | 'image' | undefined;

const toPercent = (v: number | null): string => {
  return numbro((v || 0) * 100).format({
    trimMantissa: true,
    mantissa: 4
  });
};

function ProfileModal(
  {
    groupId,
    userId,
    modifyType,
    onClose,
    onClickRebuyin,
    waitRebuyin,
  }: {
    groupId?: number,
    userId: number,
    modifyType?: ModifyType,
    onClose: () => void;
    onClickRebuyin?:()=>void;
    waitRebuyin?:number
  }
) {
  const {t} = useTranslation();
  const [myInfo, setMyInfo] = useRecoilState(myInfoState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const emoticonBanList = useRecoilValue(emoticonBanState);

  const [modify, setModify] = useState<boolean>(Boolean(modifyType));
  const [profile, setProfile] = useState<userProfileModel>();
  const [showHelp, setShowHelp] = useState(false);

  const {openDialog} = useDialog();

  const isMe = useMemo(() => {
    return userId === myInfo?.userId;
  }, [userId, myInfo?.userId]);

  const profileImg = useMemo<string>(() => {
    const idx = Number(profile?.profileImg || '0');
    return `/images/profile/${idx}.png`;
  }, [profile?.profileImg]);

  const handleCancel = useCallback(() => {
    if (!modifyType) {
      setModify(false);
    } else {
      onClose && onClose();
    }
  }, [modifyType, onClose])

  const handleConfirm = useCallback(async () => {
    if (!profile?.nickname) {
      openDialog({
        title: t('오류'),
        text: t('닉네임을 입력해주세요.')
      });
      return;
    }

    setGlobalLoading(true);
    const res = await requestModifyUserProfile(profile.nickname, profile.profileImg);
    if (!res) {
      openDialog({
        title: t('오류'),
        text: t(`프로필을 변경하는 중 오류가 발생했습니다.`)
      });
    }
    requestMyInfo().then((v: any) => setMyInfo(v.info));
    setGlobalLoading(false);

    handleCancel();
  }, [profile, handleCancel]);

  const handleChange = useCallback((e: any) => {
    if (e.target.value.length > 8) {
      return;
    }

    setProfile(produce(d => {
      if (d != null) {
        d.nickname = e.target.value;
      }
    }));
  }, []);

  const handleSelectImage = useCallback((idx: number) => {
    setProfile(produce(d => {
      if (d) {
        d.profileImg = idx.toString();
      }
    }));
  }, []);

  const handleReport = useCallback(() => {
    if (!profile || !groupId) {
      return;
    }

    openDialog({
      title: t("유저신고"),
      text: t('{{nickname}}님을 신고하시겠습니까?', {nickname: profile.nickname}),
      confirm: true,
      onConfirm: async () => {
        setGlobalLoading(true);
        await requestReport(groupId, profile.userId);
        openDialog({
          title: t("안내"),
          text: t("신고를 완료했습니다."),
        })
        setGlobalLoading(false);
      }
    });
  }, [profile]);

  useEffect(() => {
    if (userId === -1) {
      return;
    }

    setGlobalLoading(true);
    requestGetUserProfile(userId).then((profile: userProfileModel) => {
      if (!profile.nickname) {
        onClose && onClose();
        return;
      }

      setProfile(profile);
    }).finally(() => {
      setGlobalLoading(false);
    });
  }, [userId]);

  const handleEmoticonBan = useCallback(() => {
    if (userId === -1) {
      return;
    }

    requestToggleEmoticonBan(userId);
  },[userId]);

  if (!profile) {
    return null;
  }

  return <Modal>
    {
      !modify ? (
        <Content>
          <Profile>
            <img className="image" src={profileImg}/>
            <div className="info">
              <div className="name">
                {profile.nickname}
                {isMe ? null : <EmoticonToggle onClick={handleEmoticonBan} toggle={emoticonBanList.filter(e=>e == userId).length > 0}>
                  <div><img src="/images/emoticon_on.png" /></div>
                  <div><img src="/images/emoticon_off.png?v=2" /></div>
                </EmoticonToggle>}
              </div>
              <div className="hand"><span>{t('핸드')}</span>{profile.handCount}</div>
            </div>
            {
              isMe ? (
                <ModifyButton onClick={() => setModify(true)}>{t('변경')}</ModifyButton>
              ) : (
                <ReportButton onClick={handleReport}>{t('신고')}</ReportButton>
              )
            }
          </Profile>
          <Border/>
          <Stats>
            <div className="help" onClick={() => setShowHelp(true)}><img src="/images/ic_profile_help.svg"/></div>
            <div className="grid">
              <StatItem>
                <div>{t('참여율(VPIP)')}</div>
                <div>{toPercent(profile.vpip)}%</div>
                <StatGauge percentage={toPercent(profile.vpip)}/>
              </StatItem>
              <StatItem>
                <div>{t('참여 승률')}</div>
                <div>{toPercent(profile.winRate)}%</div>
                <StatGauge percentage={toPercent(profile.winRate)}/>
              </StatItem>
              <StatItem>
                <div>PFR</div>
                <div>{toPercent(profile.pfr)}%</div>
                <StatGauge percentage={toPercent(profile.pfr)}/>
              </StatItem>
              <StatItem>
                <div>3BET</div>
                <div>{toPercent(profile.bet3)}%</div>
                <StatGauge percentage={toPercent(profile.bet3)}/>
              </StatItem>
              <StatItem>
                <div>ATS</div>
                <div>{toPercent(profile.ats)}%</div>
                <StatGauge percentage={toPercent(profile.ats)}/>
              </StatItem>
            </div>
            <div className="caption">{t('최근 30일 데이터 기준')}</div>
          </Stats>
          
          { isMe && onClickRebuyin && <ButtonWrapper style={{marginBottom:"10px"}}>
            <div onClick={onClickRebuyin}>
              { waitRebuyin && waitRebuyin > 0 ? <RebuyinAmount>+{Number(waitRebuyin).toLocaleString()} <span>({t("다음 게임에 적용")})</span></RebuyinAmount> : <></> }
              {t('추가 바이인')}
            </div>
          </ButtonWrapper>}

          <ButtonWrapper>
            <div onClick={onClose}>{t('닫기')}</div>
          </ButtonWrapper>
        </Content>
      ) : (
        <Content>
          <ModalTitle>{t('정보 변경')}</ModalTitle>
          {
            (!modifyType || modifyType === 'nickname') && (
              <NicknameForm className="form">
                <div>{t('닉네임')}</div>
                <div>
                  <input value={profile.nickname} readOnly={true}/>
                </div>
              </NicknameForm>
            )
          }
          {
            !modifyType && (
              <Border/>
            )
          }
          {
            (!modifyType || modifyType === 'image') && (
              <SelectImage>
                <div>{t('프로필 이미지')}</div>
                <div className="grid">
                  {
                    Array.from({length: 36}).map((_, i) => (
                      <div key={i} className="profile-image-wrapper">
                        {
                          (Number(profile.profileImg || 0) === i) && <div className='selected'>
                            <img src='/images/ic_option_checked.svg'/>
                          </div>
                        }
                        <img className='profile-image' src={`/images/profile/${i}.png`}
                             data-selected={Number(profile.profileImg || 0) === i}
                             onClick={() => handleSelectImage(i)}/>
                      </div>
                    ))
                  }
                </div>
              </SelectImage>
            )
          }
          <ButtonWrapper>
            <div onClick={handleCancel}>{t('취소')}</div>
            <div onClick={handleConfirm}>{t('완료')}</div>
          </ButtonWrapper>
        </Content>
      )
    }
    <ModalContainer show={showHelp}>
      <Help>
        <div className="title">{t("참여율(VPIP)")}</div>
        <div className="desc">{t("프리플랍에서 팟에 참여하는 빈도의 비율")}</div>
        <div className="title">{t("참여 승률")}</div>
        <div className="desc">{t("팟에 참여한 횟수 대비 승리한 비율")}</div>
        <div className="title">PFR</div>
        <div className="desc">{t("프리플랍에서 레이즈빈도의 비율")}</div>
        <div className="title">3BET</div>
        <div className="desc">{t("레이즈 이후 상대방의 레이즈에 다시 리레이즈하는 빈도의 비율")}</div>
        <div className="title">ATS</div>
        <div className="desc">{t("컷오프 이후 자리에서 블라인드 스틸 시도 비율")}</div>
        <br/>
        <ButtonWrapper>
          <div onClick={() => setShowHelp(false)}>{t('닫기')}</div>
        </ButtonWrapper>
      </Help>
    </ModalContainer>
  </Modal>;
}

export default ProfileModal;
