import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import moment from "moment";
import {parseDatetime, parseLocalDateTime} from "../../constants/moment";
import {calcLevel, calcPlayTime} from "../../utils/tournament";
import {requestCancelJoinTournament, requestGetTournamentGroup, requestRebuyinTournament, requestRegisterOrJoinTournament} from "../../api";
import useTournamentTimer from "../../hooks/useTournamentTimer";
import TournamentStatus from "./TournamentStatus";
import Flex from "../common/Flex";
import Prize from "./Prize";
import PlayerCount from "./PlayerCount";
import useDialog from "../../hooks/useDialog";
import {useHistory} from "react-router-dom";
import {convertMinutes} from "../../utils/common";
import WinnerListModal from "../WinnerListModal";
import ModalContainer from "../common/ModalContainer";
import {GroupInfo} from "../../dataset";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useSetRecoilState} from "recoil";
import {globalLoadingState} from "../../recoil/Loading";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #101012;
  overflow-y: auto;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }

  .header {
    height: 48px;
    display: flex;
    align-items: center;

    @media ${MEDIA_DESKTOP} {
      height: 72px;
    }

    > img.back {
      padding: 14px 20px;
      cursor: pointer;

      @media ${MEDIA_DESKTOP} {
        padding: 24px;
      }
    }

    > .title {
      flex: 1;
      padding: 4px 55px 4px 0;
      color: #FFF;
      font-size: 12px;
      font-weight: 500;

      @media ${MEDIA_DESKTOP} {
        font-size: 14px
      }
    }
  }

  .gray-box {
    background: #17171B;
    color: #FFF;
    padding: 16px 20px;
    font-size: 12px;

    @media ${MEDIA_DESKTOP} {
      font-size: 14px;
    }

    .flexbox {
      display: flex;

      &.v-center {
        align-items: center;
      }
    }

    .start-info {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > div {
        color: #FFF;

        > span {
          color: #FFF;
          opacity: 0.5;
          margin-right: 2px;

          @media ${MEDIA_DESKTOP} {
            margin-right: 4px;
          }
        }
      }
    }

    > .border {
      width: 100%;
      min-height: 1px;
      height: 1px;
      background-color: #2D2D2D;
      margin: 12px 0 16px 0;
    }

    .level-blind {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0 16px;

      > .description {
        color: #FFF;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        opacity: 0.5;
        background: none !important;
      }

      > * {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.30);
        color: #FFF;
        text-align: center;
        padding: 8px 10px;
      }

      > .level {
        > div {
          color: #FFF;

          &:first-child {
            font-size: 12px;
            font-weight: 600;
            opacity: 0.5;
            margin-bottom: 4px;

            @media ${MEDIA_DESKTOP} {
              font-size: 14px;
            }
          }

          &:last-child {
            font-size: 24px;
          }
        }
      }

      > .blind {
        color: #FFF;
        font-size: 10px;
        font-weight: 600;

        > span {
          color: #FFF;
          font-size: 10px;
          font-weight: 600;
          opacity: 0.5;
          margin-right: 4px;
        }
      }
    }
  }

  ul {
    padding: 16px 20px;


    li {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      padding: 8px 10px;
      border-bottom: 1px solid #2D2D2D;

      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
        padding: 12px 10px;
      }

      > span {
        color: #FFF;
        font-size: 12px;
        font-weight: 500;
        opacity: 0.5;
        margin-right: 4px;
      }
    }
  }
`;

const RegisterButton = styled.div<{
  disabled?: boolean
}>`
  display: flex;
  min-width: 80px;
  height: 46px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
  cursor: pointer;

  @media ${MEDIA_DESKTOP} {
    width: 180px;
  }

  ${p => p.disabled && `
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.20);
    cursor: initial;
  `};
`;

const TournamentJoinDesc = styled.div`
  text-align: center;
  font-size: 13px;
  color: white;
  margin-top: 15px;
`

const WinnerButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 42px;
  color: #2F99FB;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  border-radius: 8px;
  background: rgba(47, 153, 251, 0.10);
`;

const StructureWrapper = styled.div<{
  isOpen: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  @media ${MEDIA_DESKTOP} {
    padding: 0 16px;
    font-size: 14px;
  }

  > .title-row {
    cursor: pointer;
    width: 100%;
    padding: 8px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background: #17171B;

    > .title {
      color: #FFF;
      font-weight: 500;
    }

    > .arrow {
      width: 12px;
      height: 12px;
      object-fit: contain;
      transition: all 0.3s;
      ${p => p.isOpen ? `
        transform: rotate(0deg);
      ` : `
        transform: rotate(180deg);
      `}
    }
  }

  > .content {
    ${p => p.isOpen ? `
      height: fit-content;
      padding: 12px 20px;
    ` : `
      height: 0;
      overflow: hidden;
      max-height: 0;
      padding: 0 20px;
    `}
    transition: all 0.3s;
    width: 100%;

    > .row {
      padding: 5px 0 10px;
      border-bottom: 1px solid #2D2D2D;
      height: 23px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #FFF;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;

      > .title {
        opacity: 0.5;
      }

    }

    > table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 5px;

      tr {
        height: 18px;

        th {
          padding-bottom: 7px;
          border-bottom: 1px solid #2D2D2D;

          &:first-child {
            text-align: left;
          }
        }

        td {
          &:first-child {
            text-align: left;
          }
        }
      }

      .title {
        opacity: 0.5;
        color: #FFF;
        font-weight: 500;
      }

      .value {
        color: #FFF;
        font-weight: 500;
      }

      .fixed {
        width: 203px;
        text-align: center;
      }

      .level {
        width: 40px;
        text-align: left;
      }

      .blind {
        width: 140px;
        text-align: center;
      }

      .entity {
        width: 60px;
        text-align: center;
      }

      .minute {
        width: 60px;
        text-align: center;
      }

    }
  }
`;


function TournamentDetail({
                            groupId,
                            onClose
                          }: {
  groupId: number
  onClose?: () => void
}) {
  const {t} = useTranslation();
  const history = useHistory();
  const {openDialog} = useDialog();

  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  const [group, setGroup] = useState<GroupInfo>();
  const [currentLevel, setCurrentLevel] = useState<number>(1);
  const [levelTimeText, setLevelTimeText] = useState<string>('--:--');
  const [isRegistered, setRegistered] = useState<boolean>(false);
  const [stackSize, setStackSize] = useState<number>(0);
  const [isOpenPrize, setIsOpenPrize] = useState<boolean>(false);
  const [isOpenBlind, setIsOpenBlind] = useState<boolean>(false);
  const [status, setStatus] = useState<'참가' | '참가종료' | '미참가종료'>('참가');
  const [showWinner, setShowWinner] = useState<boolean>(false);
  const [rankingData, setRankingData] = useState<{
    ranking: number;
    totalRegisterCount: number;
  }>({
    ranking: 0,
    totalRegisterCount: 0
  });

  const {
    timerText,
    isTournamentStarted,
    isClosed,
    isOpen
  } = useTournamentTimer({
    data: group?.data
  });

  useEffect(() => {
    if (!groupId) {
      return;
    }

    setGlobalLoading(true);
    requestGetTournamentGroup(Number(groupId)).then((res) => {
      setGlobalLoading(false);

      if (res.info) {
        setGroup(res.info);
        setRegistered(res.info.isRegister);

        if (res.info.stackSize) {
          setStackSize(res.info.stackSize);
        }
      } else {
        openDialog({
          title: t('오류'),
          text: t('존재하지 않는 토너먼트입니다.'),
          onConfirm: () => {
            history.goBack();
          }
        });
      }
    });
  }, [groupId]);

  useEffect(() => {
    if (!group?.data) {
      return;
    }

    if (!isTournamentStarted) {
      setCurrentLevel(1);
      setLevelTimeText('--:--');
      return;
    }

    function update(): boolean {
      if (!group?.data) {
        return true;
      }

      const startedAt = parseDatetime(group.data.startedAt);
      const {
        playTimeSeconds,
        restTimeSeconds
      } = group.data.timeStructure;
      const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds);
      const level = calcLevel(playTime, group.data.blindStructure);
      setCurrentLevel(level + 1);

      let startTime = 0;
      let blindTime = 0;
      for (let i = 0; i < level; ++i) {
        startTime += group.data.blindStructure[i][3];
      }
      blindTime = group.data.blindStructure[level][3]

      console.log(level, blindTime)
      const elapsed = blindTime - (playTime - startTime);
      setLevelTimeText(moment.unix(elapsed).format(elapsed > 3600 ? 'hh:mm:ss' : 'mm:ss'));

      return false;
    }

    update();
    const interval = setInterval(() => {
      if (update()) {
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [group?.data, isTournamentStarted]);

  const {
    blind,
    nextBlind
  } = useMemo<{
    blind: number[],
    nextBlind: number[]
  }>(() => {
    const ret = {
      blind: [],
      nextBlind: []
    };

    if (group?.data) {
      ret.blind = group.data.blindStructure[currentLevel - 1];
      if (currentLevel < group.data.blindStructure.length) {
        ret.nextBlind = group.data.blindStructure[currentLevel];
      }
    }

    return ret;
  }, [currentLevel, group?.data]);

  const descItems = useMemo<{
    label: string,
    value: string
  }[]>(() => {
    if (!group?.data) {
      return [];
    }

    const {
      startedAt,
      beginChip,
      blindStructure,
      rebuyinLimit,
      preRegisterBenefitTimeMinutes,
      preRegisterBenefitChip,
      timeStructure,
      rebuyinChip
    } = group.data;
    const beginBigBlind = blindStructure[0][1];
    const playTime = moment.unix(timeStructure.playTimeSeconds).format(t('m분'));
    const restTime = moment.unix(timeStructure.restTimeSeconds).format(t('m분'));

    return [
      {
        label: t("시작"),
        value: parseLocalDateTime(startedAt).format('MM/DD, HH:mm')
      },
      {
        label: t("바이인"),
        value: group.data.buyinPrice.toLocaleString()
      },
      {
        label: t("시작칩"),
        value: `${beginChip.toLocaleString()} (${beginChip / beginBigBlind}BB)`
      },
      {
        label: t("리바인칩"),
        value: `${rebuyinChip.toLocaleString()} (${rebuyinChip / beginBigBlind}BB)`
      },
      {
        label: t("추가등록 레벨 제한"),
        value: `${group.data.availableRegisterLevel.toLocaleString()} Level`
      },
      {
        label: t("테이블 타입"),
        value: t("테이블 당 최대 {{maxTableMember}}명, 파이널 테이블 {{finalTableMember}}명", {
          maxTableMember: group.data.maxTableMember,
          finalTableMember: group.data.finalTableMember
        })
      },
      {
        label: t("리바인 제한"),
        value: rebuyinLimit === 1 ? t('리바인 없음') : (rebuyinLimit === -1 ? t('무제한') : t('{{rebuyinLimit}}회 제한', {rebuyinLimit: rebuyinLimit-1}))
      },
      {
        label: t("최소 / 최대 플레이어"),
        value: `${group.data.minStartMember}/${group.data.maxTotalMember}`
      },
      {
        label: t("프라이즈 상향"),
        value: group.data.isGuarantee ? t("프라이즈 풀 {{ratio}}%",{
          ratio: group.data.guaranteeBuyinSplitRatio
        }) : t("없음")
      },
      {
        label: t("휴식시간"),
        value: t(`{{playTime}} 플레이 {{restTime}} 휴식`, {
          playTime: playTime,
          restTime: restTime
        })
      },
      {
        label: t("예약베네핏"),
        value: t(`시작 {{preRegisterBenefitTimeMinutes}}분 전 등록 시 {{preRegisterBenefitChip}} 추가 칩`, {
          preRegisterBenefitTimeMinutes: preRegisterBenefitTimeMinutes,
          preRegisterBenefitChip: preRegisterBenefitChip.toLocaleString()
        })
      },
    ];
  }, [group?.data]);

  const [registerButtonText, disableJoinButton] = useMemo<[string, boolean]>(() => {
    if (isTournamentStarted && isRegistered && stackSize > 0) {
      return [t('입장하기'), false];
    } else if (isTournamentStarted && isRegistered && stackSize == 0) {
      return [t('리바이인'), false];
    }else if(isOpen && isRegistered){
      return [t('입장 하기'), false];
    } else if (!isTournamentStarted && isRegistered) {
      return [t('등록 취소'), false];
    } else if (isClosed) {
      return [t('등록 불가'), true];
    }

    return [t('등록'), false];
  }, [isTournamentStarted, isClosed, isRegistered, stackSize, isOpen]);

  const handleRegister = useCallback(async () => {
    if (!group || !group.data) {
      return;
    }

    if (isClosed && !isRegistered) {
      return;
    }

    const errorHandle = (result: number) => {
      switch (result) {
        case -1: {
          openDialog({
            title: t('등록 불가 안내'),
            text: t(`현재 블라인드 레벨이 참여 가능한 레벨이 아닙니다.`)
          });
          break;
        }
        case -2: {
          openDialog({
            title: t('등록 불가 안내'),
            text: t(`바이인할 머니가 부족합니다.`)
          });
          break;
        }
        case -3: {
          openDialog({
            title: t('안내'),
            text: t(`게임 시작 10분전 부터 입장할 수 있습니다.`)
          });
          break;
        }
        case -4: {
          openDialog({
            title: t('등록 불가 안내'),
            text: t(`한번에 한 토너먼트만 등록 가능합니다.`)
          });
          break;
        }
        case -5: {
          openDialog({
            title: t('등록 불가 안내'),
            text: t(`해당 토너먼트는 종료되었습니다.`)
          });
          break;
        }
        case -6: {
          openDialog({
            title: t('리바이인 불가 안내'),
            text: t(`이미 리바이인 제한 횟수만큼 리바이인 하셨습니다.`)
          });
          break;
        }
        case -7: {
          openDialog({
            title: t('입장 불가 안내'),
            text: t(`칩이 없어 입장할 수 없습니다. 리바이인 해주세요.`)
          });
          break;
        }
        case -8: {
          openDialog({
            title: t('입장 불가 안내'),
            text: t(`칩이 남아있어 리바이인 할 수 없습니다.`)
          });
          break;
        }
        case -11:{
          openDialog({
            title: t('안내'),
            text: t('보유금을 차감에 실패했습니다. 관리자에게 문의해주세요.')
          });
          break;
        }
        case -101:{
          openDialog({
            title: t('안내'),
            text: t('보유 금액을 불러오지 못했습니다. 관리자에게 문의해주세요.')
          });
          break;
        }
        
      }
    };

    if(!isOpen && isRegistered){
      openDialog({
        title: t('토너먼트 등록 취소'),
        text: t(`토너먼트 시작 {{preRegisterBenefitTimeMinutes}}분전까지 취소가 가능합니다.<br>등록을 취소하겠습니까?`, {
          preRegisterBenefitTimeMinutes: group.data.preRegisterBenefitTimeMinutes.toLocaleString(),
        }),
        confirm: true,
        confirmText: t('등록 취소'),
        cancelText: t('닫기'),
        onConfirm: async () => {
          let r = await requestCancelJoinTournament(group.groupId);
          if (typeof (r.result) == "number") {
            if (r.result < 1) {
              switch (r.result) {
                case -1: {
                  openDialog({
                    title: t('안내'),
                    text: t(`토너먼트 정보를 불러오지 못했습니다.`)
                  });
                  break;
                }
                case -2: {
                  openDialog({
                    title: t('안내'),
                    text: t(`토너먼트 시작 10분전까지만 취소 가능합니다.`)
                  });
                  break;
                }
                case -3: {
                  openDialog({
                    title: t('안내'),
                    text: t(`토너먼트에 등록되지 않았습니다.`)
                  });
                  break;
                }
                case -11: {
                  openDialog({
                    title: t('안내'),
                    text: t(`보유금을 갱신에 실패했습니다. 관리자에게 문의해주세요.`)
                  });
                  break;
                }
                case -4: {
                  openDialog({
                    title: t('안내'),
                    text: t(`칩이 없어 입장할 수 없습니다. 리바이인 해주세요.`)
                  });
                  break;
                }
              }
            } else {
              openDialog({
                title: t('안내'),
                text: t(`정상적으로 취소되었습니다.`)
              });
              setRegistered(false);
              setStackSize(0);
            }
          }
        }
      });
    }else if (isRegistered && stackSize == 0) {
      if(group.data.rebuyinChip == 0){
        openDialog({
          title: t('바이인 안내'),
          text: t(`리바인이 불가능한 토너먼트입니다.`),
        });
      }else{
        openDialog({
          title: t('바이인 안내'),
          text: t(`리바이인 시 {{rebuyinChip}}을 얻고, {{buyinPrice}}를 지출하게 됩니다.`, {
            rebuyinChip: group.data.rebuyinChip.toLocaleString(),
            buyinPrice: group.data.buyinPrice.toLocaleString()
          }),
          confirm: true,
          confirmText: t('리바인'),
          cancelText: t('취소'),
          onConfirm: async () => {
            let r = await requestRebuyinTournament(group.groupId);
            if (typeof (r.result) == "number") {
              if (r.result < 1) {
                errorHandle(r.result);
              } else {
                setStackSize(1);
                history.push("/game?id=" + r.result);
              }
            }
          }
        });
      }
    } else if (isRegistered) {
      let r = await requestRegisterOrJoinTournament(group.groupId);
      if (typeof (r.result) == "number") {
        if (r.result < 1) {
          errorHandle(r.result);
        } else {
          history.push("/game?id=" + r.result);
        }
      } else if (r.result === true) {
        setRegistered(true);
        setStackSize(1);
      }
    } else {
      let isGo = await new Promise(r => {
        openDialog({
          title: t('등록 안내'),
          text: t(`토너먼트에 등록하시겠습니까?<br/>등록을 위해서는 {{buyinPrice}}머니가 필요합니다`, {
            buyinPrice: group.data.buyinPrice.toLocaleString()
          }),
          disableBackdropClick: true,
          confirm: true,
          confirmText: t("등록"),
          cancelText: t("취소"),
          onConfirm: () => {
            r(true);
          },
          onCancel: () => {
            r(false);
          }
        });
      });

      if (!isGo) return;

      let data = await requestRegisterOrJoinTournament(group.groupId);
      if (data.result === true) {
        openDialog({
          title: t('안내'),
          text: t(`토너먼트 등록 완료했습니다.`)
        });
        if (!!data.isBenefit && data.isBenefit) {
          openDialog({
            title: t('베네핏'),
            text: t(`미리 등록하셔서 토너먼트 시작 시 추가칩을 받습니다.`)
          });
        }
        setRegistered(true);
        setStackSize(1);
      } else if (typeof (data.result) === "number" && data.result > 0) {
        history.push("/game?id=" + data.result);
      } else {
        errorHandle(Number(data.result));
      }
    }
  }, [group?.groupId, group?.data, isRegistered, isClosed, isTournamentStarted, isOpen]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  }, [onClose]);

  useEffect(() => {
    if (!group) {
      return;
    }

    if (group.isEnd && group.isRegister) {
      setStatus('참가종료');
    } else if (group.isEnd && !group.isRegister) {
      setStatus('미참가종료');
    } else if (!group.isEnd && group.isRegister) {
      setStatus('참가');
    }
  }, [group?.isEnd, group?.isRegister]);


  useEffect(() => {
    if (group && group.isEnd) {
      requestGetTournamentGroup(group.groupId).then(data => {
        setRankingData({
          ranking: data.info.ranking,
          totalRegisterCount: data.info.totalRegisterCount
        });
        setGroup(data.info);
        setRegistered(data.info.isRegister);
        if (data.info.stackSize) setStackSize(data.info.stackSize);
      });
    }
  }, [group?.groupId, group?.isEnd]);

  const gtd = useMemo(function(){
    if(group?.data.isGuarantee){
      const gtd = Math.max(group.totalBuyin / 100 * group.data.guaranteeBuyinSplitRatio - group.data.guaranteeMinAmount,0);
      return gtd;
    }
    return 0;
  },[group?.totalBuyin, group?.data.isGuarantee])

  const addPrize = useMemo(function(){
    if(gtd > 0 && group?.data.prize){
      let total = group.data.prize.reduce((acc:any,cur:any)=>acc+cur,0);
      return group.data.prize.map((v:any)=>Math.floor(v/total*gtd));
    }
    return group?.data.prize.map((v:any)=>0);
  },[group?.data.prize, gtd])

  if (!group) {
    return null;
  }

  return <Wrapper>
    <ModalContainer show={showWinner}>
      <WinnerListModal
        prizeData={group.data.prize}
        groupId={group.groupId}
        onClose={() => setShowWinner(false)}/>
    </ModalContainer>
    <div className="header">
      <img className="back" src="/images/ic_header_back.svg" onClick={handleClose}/>
      <div className="title">{group.data.name}</div>
    </div>
    {
      status === '참가' && <div className="gray-box">
        <div className="flexbox v-center">
          <TournamentStatus detail ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
          <div className="start-info">
            <div>
              <span>{t('시작')}</span>{descItems[0].value}
            </div>
            <div>
              <span>{t('시작칩')}</span>{descItems[2].value}
            </div>
            <div>
              <span>{t('바이인')}</span>{descItems[1].value}
            </div>
          </div>
          <Flex/>
          <RegisterButton onClick={handleRegister} disabled={disableJoinButton}>
            {registerButtonText}
          </RegisterButton>
        </div>
        <div className="border"/>
        <div className="flexbox">
          <Prize gtd={gtd} prizes={group.data.prize} detail/>
          <div className="level-blind">
            <div className="level">
              <div>{t('레벨')} {currentLevel}</div>
              <div>{levelTimeText}</div>
            </div>
            {
              blind.length === 2 && (
                <div className="blind">
                  <span>{t('블라인드')}</span>
                  {blind[0].toLocaleString()} / {blind[1].toLocaleString()}
                </div>
              )
            }
            {
              nextBlind.length === 2 && (
                <div className="blind">
                  <span>{t('다음 블라인드')}</span>
                  {nextBlind[0].toLocaleString()} / {nextBlind[1].toLocaleString()}
                </div>
              )
            }
          </div>
          <PlayerCount count={group.totalRegisterCount ?? 0} max={group.buyinCount}/>
        </div>
        <TournamentJoinDesc>{t("토너먼트 시작 10분전부터 입장가능합니다.")}</TournamentJoinDesc>
      </div>
    }
    {
      status === '참가종료' && <div className="gray-box">
        <div className="flexbox v-center">
          <TournamentStatus detail ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
          <div className="start-info">
            <div>
              <span>{t('시작')}</span>{descItems[0].value}
            </div>
            <div>
              <span>{t('시작칩')}</span>{descItems[2].value}
            </div>
            <div>
              <span>{t('바이인')}</span>{descItems[1].value}
            </div>
          </div>
          <Flex/>
          <WinnerButton onClick={() => setShowWinner(true)}>
            {t('우승자 리스트 보기')}
          </WinnerButton>
        </div>
        <div className="border"/>
        <div className="flexbox">
          <Prize gtd={gtd} prizes={group.data.prize} detail/>
          <div className="level-blind">
            <div className="level">
              <div>{t('내 순위')}</div>
              <div>{rankingData.ranking} / {rankingData.totalRegisterCount.toLocaleString()}</div>
            </div>
            {
              blind.length === 2 && (
                <div className="blind">
                  <span>{t('블라인드')}</span>
                  {blind[0].toLocaleString()} / {blind[1].toLocaleString()}
                </div>
              )
            }
            {
              nextBlind.length === 2 && (
                <div className="blind">
                  <span>{t('다음 블라인드')}</span>
                  {nextBlind[0].toLocaleString()} / {nextBlind[1].toLocaleString()}
                </div>
              )
            }
          </div>
          <PlayerCount count={group.totalRegisterCount} max={group.buyinCount}/>
        </div>
      </div>
    }
    {
      status === '미참가종료' && <div className="gray-box">
        <div className="flexbox v-center">
          <TournamentStatus detail ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
          <div className="start-info">
            <div>
              <span>{t('시작')}</span>{descItems[0].value}
            </div>
            <div>
              <span>{t('시작칩')}</span>{descItems[2].value}
            </div>
            <div>
              <span>{t('바이인')}</span>{descItems[1].value}
            </div>
          </div>
          <Flex/>
          <WinnerButton onClick={() => setShowWinner(true)}>
            {t('우승자 리스트 보기')}
          </WinnerButton>
        </div>
        <div className="border"/>
        <div className="flexbox">
          <Prize gtd={gtd} prizes={group.data.prize}/>
          <div className="level-blind">
            <div className="description">
              {t('참여하지 않은 토너먼트입니다.')}
            </div>
            {
              blind.length === 2 && (
                <div className="blind">
                  <span>{t('블라인드')}</span>
                  {blind[0].toLocaleString()} / {blind[1].toLocaleString()}
                </div>
              )
            }
            {
              nextBlind.length === 2 && (
                <div className="blind">
                  <span>{t('다음 블라인드')}</span>
                  {nextBlind[0].toLocaleString()} / {nextBlind[1].toLocaleString()}
                </div>
              )
            }
          </div>
          <PlayerCount count={group.totalRegisterCount} max={group.buyinCount}/>
        </div>
      </div>
    }
    <ul>
      {
        descItems.map((item, i) => (
          <li key={i}>
            <span>{item.label}</span>{item.value}
          </li>
        ))
      }
    </ul>
    {
      status === '참가' && <>
        <StructureWrapper isOpen={isOpenPrize} onClick={() => setIsOpenPrize(!isOpenPrize)}>
          <div className="title-row">
            <span className="title">{t('상금 구조')}</span>
            <img className="arrow" src="/images/ic_fold.svg"/>
          </div>
          <div className="content">
            <table>
              <thead>
              <tr className="title bottom">
                <th>{t('등수')}</th>
                <th className="fixed">{t('상금')}</th>
              </tr>
              </thead>
              <tbody>
              {
                group.data.prize.map((item: number, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="title">{t('{{rank}}등', {rank: index + 1})}</td>
                      <td className="fixed value">{(item+addPrize[index]).toLocaleString()}</td>
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
          </div>
        </StructureWrapper>
        <StructureWrapper isOpen={isOpenBlind} onClick={() => setIsOpenBlind(!isOpenBlind)}>
          <div className="title-row">
            <span className="title">{t('블라인드 구조')}</span>
            <img className="arrow" src="/images/ic_fold.svg"/>
          </div>
          <div className="content">
            <div className="row">
              <span className="title">{t('시작 칩')}</span>
              <span>{descItems[2].value}</span>
            </div>
            <table>
              <thead>
              <tr className="title top bottom">
                <th className="level">{t('레벨')}</th>
                <th className="blind">{t('블라인드')}</th>
                <th className="entity">{t('앤티')}</th>
                <th className="minute">{t('{{m}}분', {m: ''})}</th>
              </tr>
              </thead>
              <tbody>
              {
                group.data.blindStructure.map((item: number[], index: number) => {
                  return <tr key={index}>
                    <td className="level title">{index + 1}</td>
                    <td className="blind value">{item[0].toLocaleString()} / {item[1].toLocaleString()}</td>
                    <td className="entity value">{item[2].toLocaleString()}</td>
                    <td className="minute value">{convertMinutes(item[3])}</td>
                  </tr>;
                })
              }
              </tbody>
            </table>
          </div>
        </StructureWrapper>
      </>
    }
  </Wrapper>;
}

export default TournamentDetail;
