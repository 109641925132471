import styled from "styled-components";
import {useMemo} from "react";
import {BET_TYPE} from "../../dataset";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{
  act: number
}>`
  position: absolute;
  min-width: 44px;
  border-radius: 5px;
  border: 1px solid #FFF;
  background: rgba(16, 16, 18, 0.80);
  box-shadow: 0 0 6px 0 rgba(70, 155, 255, 0.25);
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 5px;
  
  @media ${MEDIA_DESKTOP} {
    min-width: 58px;
    font-size: 12px;
    padding: 4px 8px;
  }

  span {
    line-height: 1;
    color: #FFF;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.80));
  }

  ${p => p.act === BET_TYPE.FOLD && `
    border: 1px solid #FF3E3E;
    box-shadow: 0 0 6px 0 rgba(255, 62, 62, 0.25);
    
    span {
      color: #FF3E3E;
    }
  `};

  ${p => (p.act === BET_TYPE.CALL || p.act === BET_TYPE.CHECK) && `
    border: 1px solid #FFE895;
    box-shadow: 0 0 6px 0 rgba(255, 232, 149, 0.25);

    span {
      color: #FFE895;
    }
  `};

  ${p => p.act === BET_TYPE.RAISE && `
    border: 1px solid #5DD3BC;
    box-shadow: 0 0 6px 0 rgba(93, 211, 188, 0.25);
      
    span {
      color: transparent;
      background: linear-gradient(to bottom, #FBFFFF, #5DD3BC);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `};

  ${p => p.act === BET_TYPE.ALLIN && `
    border: 1px solid #469BFF;
    box-shadow: 0 0 6px 0 rgba(93, 211, 188, 0.25);
    span {
      background: linear-gradient(to bottom, #FBFFFF, #469BFF);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `};
`;

function ActionTag({act}: {
  act: number
}) {
  const typeText = useMemo(() => {
    switch (act) {
      case BET_TYPE.FOLD:
        return 'FOLD';
      case BET_TYPE.CALL:
        return 'CALL';
      case BET_TYPE.CHECK:
        return 'CHECK';
      case BET_TYPE.RAISE:
        return 'RAISE';
      case BET_TYPE.ALLIN:
        return 'ALL IN';
      case BET_TYPE.BET:
        return 'BET';
      case BET_TYPE.SB:
        return 'SB';
      case BET_TYPE.BB:
        return 'BB';
      case BET_TYPE.STR:
        return 'STR';
    }
  }, [act]);

  return <Wrapper className="action" act={act}>
    <span>{typeText}</span>
  </Wrapper>;
}

export default ActionTag;