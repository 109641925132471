import {Sounds} from "./sound";

const CHIP_SIZES = [1_000_000, 100_000, 10_000, 1_000, 100]; // 1M, 100K, 10K, 1K, 100
const MAX_CHIP = 10;

export type Chips = {
  size: number;
  amount: number;
}[];

export function minimumChips(n: number): Chips {
  const chips: Chips = [];

  for (let size of CHIP_SIZES) {
    let cnt = Math.floor(n / size);
    if (cnt > 0) {
      n %= size;
    }

    while (cnt > 0) {
      chips.push({
        size: size,
        amount: Math.min(cnt, MAX_CHIP)
      });

      cnt -= MAX_CHIP;
    }
  }

  return chips;
}

export function moveChipsSound(n: number): string {
  const chips = minimumChips(n);
  const totalChipCounts = chips.reduce((a, v) => a + v.amount, 0);
  if (totalChipCounts > 40) {
    return Sounds.SFX_BET_BIG;
  } else if (totalChipCounts > 20) {
    return Sounds.SFX_BET_MIDDLE;
  } else {
    return Sounds.SFX_BET_SMALL;
  }
}