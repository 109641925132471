import styled from "styled-components";
import Flex1 from "./common/Flex";
import {useCallback, useEffect, useMemo, useState} from "react";
import {MyInfo} from "../dataset";
import {requestMyGroupVaultList, requestMyInfo} from "../api";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";

const Wrapper = styled.div`
  width: 100%;
  min-width: 311px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
`;

const MinMaxBuyIn = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

  > div {
    display: flex;
    color: #FFF;
    font-size: 10px;
    font-weight: 600;

    .item-name {
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      opacity: 0.5;
      margin-right: 2px;
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2D2D2D;

  input {
    width: 100%;
    flex: 1;
    font-size: 32px;
    color: #FFF;
    padding: 8px;
    background-color: transparent;
    outline: none;
    border: none;

    &::placeholder {
      color: #FFF;
      opacity: 0.5;
    }
  }
`;

const ShortcutButtons = styled.div`
  display: flex;
  gap: 4px;
  padding: 16px 0;

  > div {
    flex: 1;
    padding: 12px;
    border-radius: 6px;
    background: #1F1F1F;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &.max {
      flex: initial;
    }
  }
`;

const Reentry = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  >div{
    font-size: 15px;
    color: white;
  }
`

const BalanceInfo = styled.div`
  margin: 26px 0 42px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .row {
    display: flex;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .item-name {
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
      opacity: 0.5;
      margin-left: 4px;
      margin-right: 2px;
    }
  }
`;

const BuyInButton = styled.div<{
  disabled?: boolean
}>`
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  cursor: pointer;

  ${p => p.disabled && `
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.20);
    cursor: initial;
  `};
`;

interface BuyInModalProps {
  bigBlind: number;
  minBuyIn: number;
  maxBuyIn: number;
  groupId: number;
  currentStack: number;
  onClickBuyIn: (buyIn: number) => void;
}

function BuyInModal(
  {
    bigBlind,
    minBuyIn,
    maxBuyIn,
    groupId,
    currentStack,
    onClickBuyIn
  }: BuyInModalProps
) {
  const {t} = useTranslation();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [value, setValue] = useState<string>('0');
  const [myInfo, setMyInfo] = useState<MyInfo>();
  const [vault, setVault] = useState<{amount:number, buyin:number, hands:number}|null>(null);

  const numVal = useMemo<number>(() => {
    return Number(value.replace(/[^0-9]/g, ''));
  }, [value]);

  const isValidValue = useMemo<boolean>(() => {
    if (vault) return true;
    console.log(currentStack)
    if (currentStack > 0){
      if(numVal > maxBuyIn) return false;
      if(numVal > 0) return true;
    }

    if (!value || numVal < minBuyIn || numVal > maxBuyIn) {
      return false;
    } else if (!myInfo || myInfo.money < numVal) {
      return false;
    }

    return true;
  }, [numVal, minBuyIn, maxBuyIn, myInfo?.money, vault, currentStack]);

  useEffect(() => {
    setGlobalLoading(true);
    requestMyInfo().then((v: any) => {
      setMyInfo(v.info)
    }).finally(() => {
      setGlobalLoading(false);
    });

    requestMyGroupVaultList().then((v: any) => {
      v.list.forEach((item: any) => {
        if (item.groupId === groupId) {
          setVault(item);
        }
      });
    });
  }, []);

  const handleInput = useCallback((e: any) => {
    if (e.nativeEvent?.data && (/[^0-9]/g).test(e.nativeEvent.data)) {
      e.preventDefault();
      return;
    }

    const v = (e.target?.value ?? 0).toString().replace(/[^0-9]/g, '')
    setValue(v === '' ? '' : Number(v).toLocaleString())
  }, []);

  const handleBuyIn = () => {
    if (!isValidValue)
      return;

    onClickBuyIn && onClickBuyIn(Number(numVal));
  }

  const handleSum = (num: number) => {
    let newValue;

    if (num === -1) {
      newValue = myInfo?.money ?? 0;
    } else if (num === -2) {
      newValue = 0;
    } else {
      newValue = Number(numVal || 0) + num;
    }

    setValue(Math.min(maxBuyIn, newValue).toLocaleString());
  }

  return <Wrapper>
    <Title>{t('바이인')}</Title>
    {vault ? <>
      <Reentry> 
        <div>{t("이전 플레이에서 남은 칩으로 게임을 시작합니다.")}</div>
        <InputWrapper>
          <img src="/images/ic_buyin_chips.svg"/>
          <input defaultValue={Number(vault.amount).toLocaleString()} disabled/>
        </InputWrapper>
      </Reentry>
    </>:<>
      <MinMaxBuyIn>
        <div>
          <div className="item-name">MIN</div>
          {minBuyIn.toLocaleString()} ({minBuyIn / bigBlind}BB)
        </div>
        <Flex1/>
        <div>
          <div className="item-name">MAX</div>
          {maxBuyIn.toLocaleString()} ({maxBuyIn / bigBlind}BB)
        </div>
      </MinMaxBuyIn>
      <InputWrapper>
        <img src="/images/ic_buyin_chips.svg"/>
        <input value={value || ''} placeholder={t("충전 수량")} onInput={handleInput}/>
      </InputWrapper>
      <ShortcutButtons>
        <div onClick={() => handleSum(5000)}>5,000</div>
        <div onClick={() => handleSum(10000)}>10,000</div>
        <div onClick={() => handleSum(100000)}>100,000</div>
        <div className="max" onClick={() => handleSum(-1)}>{t('최대')}</div>
        <div className="max" onClick={() => handleSum(-2)}>{t('정정')}</div>
      </ShortcutButtons>
      <BalanceInfo>
        <div className="row">
          <span className="item-name">{t('마이칩')}</span>
          {(myInfo?.money ?? 0).toLocaleString()}
          <span className="item-name"> - {t('바이인')}</span>
          {numVal.toLocaleString()}
        </div>
        <div className="row">
          <div className="item-name">= {t('밸런스')}</div>
          {((myInfo?.money ?? 0) - numVal).toLocaleString()}
        </div>
      </BalanceInfo>
    </>}
    <BuyInButton disabled={!isValidValue} onClick={handleBuyIn}>{t('바이인')}</BuyInButton>
  </Wrapper>
}

export default BuyInModal