import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import useRingGroupListRefresh from "../api/from_server_game_refreshRingGroupList";
import useTournamentGroupListRefresh from "../api/from_server_game_refreshTournamentGroupList";
import useNoticeListRefresh from "../api/game_getNoticelist";
import {
  requestGetNoticelist,
  requestJoinLobby,
  requestJoinRingGroup,
  requestRingGroupList,
  requestTournamentGroupList,
  requestMyInfo,
  requestMyGroupVaultList,
  requestGetHandCuttingList,
  requestDoCashoutVault,
  requestCanCashoutVault
} from '../api';
import {Link, useHistory} from 'react-router-dom';
import {RAKE_TYPE} from '../dataset';
import useDialog from "../hooks/useDialog";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {myInfoState} from "../recoil/MyInfo";
import Flex from "../components/common/Flex";
import PlayerCount from "../components/lobby/PlayerCount";
import TournamentItem from "../components/lobby/TournamentItem";
import ModalContainer from "../components/common/ModalContainer";
import ProfileModal from "../components/ProfileModal";
import useQueryParams from "../hooks/useQueryParams";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import RightDrawer from "../components/common/RightDrawer";
import TournamentDetail from "../components/lobby/TournamentDetail";
import {globalLoadingState} from "../recoil/Loading";
import Menu from "../components/lobby/Menu";
import {bigBlindToGameStyle} from "../utils/ring";
import Setting from "../components/lobby/Setting";
import {useTranslation} from "react-i18next";
import LeaveSettlement from '../components/game/LeaveSettlement';
import { request } from 'http';
import { parseDatetime } from '../constants/moment';
import moment from 'moment';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 320px;
  color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_DESKTOP} {
    padding: 0 32px;
  }
`;

const MyInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  padding: 12px 20px;
  margin-top: 10px; // 임시
  color: white;

  @media ${MEDIA_DESKTOP} {
    padding: 41px 0 0 0;
    margin-top: 0;
    align-items: center;
  }
`;

const Title = styled(Flex)`
  color: #FFF;
  font-size: 18px;
  margin-right: 16px;

  @media ${MEDIA_DESKTOP} {
    font-size: 24px;

    > * {
      display: inline-block;
    }
  }
`;

const TableCountDiv = styled.div`
  display: flex;
  margin-left: 10px;
  gap: 4px;
  color: rgb(47, 153, 251);
  align-items: center;

  >img{
    height: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
`

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;


  @media ${MEDIA_DESKTOP} {
    position: relative;
    flex-direction: row;
  }

  > div:first-child {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 4px;
  object-fit: cover;
  cursor: pointer;

  @media ${MEDIA_DESKTOP} {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
`;

const UserName = styled.span`
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
`;

const MyPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  gap: 4px;

  @media ${MEDIA_DESKTOP} {
    padding: 11px 12px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    font-size: 12px;
    border-radius: 28px;
    border: 1px solid #373737;
  }

  img {
    width: 12px;
    height: 12px;
  }
`;

const PointText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-weight: 400;
`;

const MenuTitle = styled.div`
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${MEDIA_DESKTOP} {
    padding: 0 16px;
    color: transparent;
  }
`;

const RefreshButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  border-radius: 6px;
  background: #1F1F1F;
  padding: 8px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.5px;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  font-size: 28px;
  font-weight: bold;
  overflow: auto;
  display: flex;
  flex-direction: column;

  > ul, > table {
    width: 100%;
  }

  > ul > li {
    &:hover {
      cursor: pointer;
    }
  }
`;

const LobbyTable = styled.div`
  flex: 1;
  width: 100%;
  font-size: 14px;
  margin: 0 auto;

  .table-row {
    display: flex;

    > div {
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table-head {
    font-weight: 500;
    background: #17171B;
    border-radius: 4px;

    > .table-row {
      border-right: 4px;

      > div {
        position: relative;

        &:not(:last-of-type) {
          &::after {
            position: absolute;
            right: 0;
            top: calc(50% - 6px);
            width: 2px;
            height: 12px;
            background-color: #2D2D2D;
            content: ' ';
          }
        }
      }
    }
  }

  .table-body {
    border-radius: 4px;

    .table-row {
      cursor: pointer;
      border-bottom: 1px solid #17171B;
      font-size: 14px;
      font-weight: 400;
      transition: all 0.1s linear;

      &:hover {
        border-radius: 8px;
        background: #1F1F25;
      }
    }
  }

  .lobby-ring {
    .table-row {
      > div {
        padding: 10px 35px;

        &:nth-of-type(1) {
          justify-content: flex-start;
          min-width: 136px;
          padding-left: 24px;
          font-weight: 600;
        }

        &:nth-of-type(2) {
          justify-content: flex-start;
          min-width: 230px;
          font-weight: 600;
        }

        &:nth-of-type(3) {
          min-width: 190px;
        }

        &:nth-of-type(4) {
          min-width: 158px;
        }

        &:nth-of-type(5) {
          min-width: 190px;
        }

        &:nth-of-type(6) {
          min-width: 111px;
        }
      }
    }
  }

  .lobby-tournament {
    .table-row {
      > div {
        padding: 10px 8px;

        &:nth-of-type(1) {
          min-width: 104px;
          font-weight: 600;
        }

        &:nth-of-type(2) {
          justify-content: flex-start;
          min-width: 497px;
          font-weight: 600;
        }

        &:nth-of-type(3) {
          min-width: 89px;
        }

        &:nth-of-type(4) {
          min-width: 105px;
        }

        &:nth-of-type(5) {
          min-width: 82px;
        }

        &:nth-of-type(6) {
          min-width: 136px;
        }
      }
    }
  }
`;

const Grid = styled.div<{
  cellWidth?: number
}>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(168px, auto));
  grid-auto-flow: row;
  grid-gap: 16px 7px;
  padding: 16px;
  justify-content: center;
  
  @media ${MEDIA_DESKTOP} {
    grid-template-columns: repeat(auto-fit, minmax(324px, auto));
  }
`;

const RingItem = styled.div`
  position: relative;

  img.cover {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    background: #343434;
    overflow: hidden;
    object-fit: cover;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }
`;

const RingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  color: #FFF;
  font-weight: 600;

  > div.row {
    > span {
      opacity: 0.5;
      font-weight: 500;
      margin-right: 2px;

      &:not(:first-of-type) {
        margin-left: 6px;
      }
    }
  }
`;

const TableCount = styled.div`
  position: relative;
  width: 20px;
  height: 14px;

  background-image: url(/images/table_count_bg.svg?v=1);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 12px;
  color: #FFF;
  padding: 6px;
  margin-bottom: 2px;
`;

const RingStatus = styled.div<{
  maxTableMember: number
}>`
  text-align: center;

  .max-seat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 0px;
    left: -2px;
    border-radius: 50%;
    background-color: ${p => p.maxTableMember > 6 ? '#8343D3' : '#3C72BA'};
    font-size: 9px;
    color: #FFF;
  }

  @media ${MEDIA_DESKTOP} {
    text-align: left;
    color: ${p => p.maxTableMember > 6 ? '#8343D3' : '#3C8FFF'};

    * {
      display: inline-block;

      &.table-count {
        margin-left: 8px;
        font-size: 14px;
        padding: 8px;
      }
    }
  }
`;

const RippleAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const Ripple = styled.div<{ delayed: number }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #906CEE;
  position: absolute;
  top: 10px;
  right: 10px;
  /* transform: translate(-50%, -50%); */
  opacity: 0;
  
  animation: ${RippleAnimation} 2s linear infinite;
  animation-delay: ${props => props.delayed}s;
  pointer-events: none;
  /* z-index: -1; */
`;

const NoticeTitle = styled.div`
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 132%; /* 15.84px */
`;

const EmptyView = styled.div`
  height: calc(100% - 51px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;

  img {
    margin-bottom: 20px;
  }


  @media ${MEDIA_DESKTOP} {
    height: 100%;
  }
`;

const JoinRoomButtons = styled.div`
  width: 100%;
  display: flex;
  gap:3px;
  margin-top: 5px;

  >div{
    cursor: pointer;
    flex:1;
    padding: 8px 0;
    text-align: center;
    border-radius: 8px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.adjust{
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #ECB322 23.2%, #C38424 98.42%);
    }

    &:hover{
      opacity: 0.8;
    }

    &:active{
      opacity: 0.6;
    }
  }
`

const MENU_CASH = 'cash';
const MENU_TOURNAMENT = 'tournament';
const MENU_NOTICE = 'notice';
const MENU_SETTING = 'setting'

// eslint-disable-next-line import/no-anonymous-default-export
export default (): JSX.Element => {
  const {t} = useTranslation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const {openDialog} = useDialog();
  const orientation = useScreenOrientation();
  const myInfo = useRecoilValue(myInfoState);
  const setMyInfo = useSetRecoilState(myInfoState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [ringGroups] = useRingGroupListRefresh();
  const [tournamentGroups] = useTournamentGroupListRefresh();
  const [notices] = useNoticeListRefresh();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [myVaults, setMyVaults] = useState<{
    groupId:number,
    amount:number,
    buyin:number,
    hands:number
  }[]>([]);
  const [showProfile, setShowProfile] = useState<boolean>(false);

  const menu = useMemo<string>(() => {
    return queryParams.get('menu') || MENU_CASH;
  }, [queryParams]);

  const handleClickMenu = useCallback((menu: string) => {
    queryParams.set("menu", menu);
    history.replace({search: queryParams.toString()});
  }, [queryParams]);

  const handleRefresh = useCallback(async (menu: string) => {
    setGlobalLoading(true);
    if (menu === MENU_CASH) {
      await requestRingGroupList();
    } else if (menu === MENU_TOURNAMENT) {
      await requestTournamentGroupList();
    } else if (menu === MENU_NOTICE) {
      await requestGetNoticelist();
    }
    requestMyInfo().then(v=>{ 
      setMyInfo(v.info);
    });
    setGlobalLoading(false);
  }, []);

  const handleClickJoin = useCallback(async function (groupId: number) {
    setGlobalLoading(true);
    let r = await requestJoinRingGroup(groupId);
    setGlobalLoading(false);
    if (typeof (r.result) == "number") {
      if (r.result == -1) {
        openDialog({
          title: t('오류'),
          text: t('현재 진행중인 게임이 있어 입장할 수 없습니다.'),
          onConfirm: () => {
            window.location.reload();
          }
        });
      } else
        history.push("/game?id=" + r.result);
    }
  }, []);

  useEffect(function () {
    handleRefresh(menu);
  }, [menu]);

  useEffect(function () {
    requestJoinLobby();
    requestMyGroupVaultList().then(v=>{
      setMyVaults(v.list || []);
    });
    return () => {
      requestJoinLobby();
    };
  }, []);

  useEffect(function(){
    requestMyInfo().then(v=>{
      setMyInfo(v.info);
    });
    requestTournamentGroupList();
  },[])

  const handleClickTournament = useCallback((groupId: number) => {
    setSelectedGroupId(groupId);
  }, []);

  const onClickCashOut = useCallback(async(groupId:number)=>{
    const check = await requestCanCashoutVault(groupId);
    if(check.result == -1){
      return openDialog({
        title: t('게임 진행 중'),
        text: t('현재 게임 진행 중입니다. 게임이 끝난 뒤 정산하실 수 있습니다.'),
        onConfirm: () => {
        }
      });
    }

    const _vaults = await requestMyGroupVaultList()
    const vaults = _vaults.list || [];
    const res = await requestGetHandCuttingList();
    const vault = vaults.find(v=>v.groupId == groupId);
    if( vault == null ) return alert("정산이 없습니다.");
    console.log(vault)
    
    const handCuttingList = res.list;
    let settle = vault.amount;
    let buyin = vault.buyin;
    let winAmount = settle - buyin;
    let lossPercentage = 0;
    let maxHands = 0;
    let cuttingList = res.list.sort((a, b) => {
      return a.handCount - b.handCount
    })
    maxHands = cuttingList[cuttingList.length - 1].handCount;

    for (let v of handCuttingList) {
      if (vault.hands <= v.handCount) {
        lossPercentage = v.lossPercentage
        let lossAmount = Math.floor(winAmount * (lossPercentage) / 100);
        settle = settle - lossAmount;
        break;
      }
    }
    if (winAmount < 0) {
      settle = vault.amount;
      winAmount = 0;
    }
    const leave = await new Promise<boolean>((r) => {
      openDialog({
        title: t("정산"),
        component: <LeaveSettlement
          hands={vault.hands}
          stack={vault.amount}
          buyin={buyin}
          winAmount={winAmount}
          lossPercentage={lossPercentage}
          settle={settle}
          maxHands={maxHands+1}
          cuttingList={cuttingList}
          lack={maxHands >= vault.hands}
        />,
        confirm: true,
        confirmText: t('정산하기'),
        cancelText: t('취소'),
        onConfirm: () => {
          r(true);
        },
        onCancel: () => {
          r(false);
        }
      });
    });

    if(leave){
      requestDoCashoutVault(groupId).then(v=>{
        if(v.result == 1){
          openDialog({
            title: t('정산 완료'),
            text: t('정산이 완료되었습니다.'),
            onConfirm: () => {
            }
          });

          requestMyInfo().then(v=>{
            setMyInfo(v.info);
          });
          requestMyGroupVaultList().then(v=>{
            setMyVaults(v.list || []);
          });
        }else if(v.result == -1){
          openDialog({
            title: t('게임 진행 중'),
            text: t('현재 게임 진행 중입니다. 게임 종료 후 정산하실 수 있습니다.'),
            onConfirm: () => {
            }
          });
        }else if(v.result == -2){
          openDialog({
            title: t('정산 실패'),
            text: t('정산 할 내용이 없습니다.'),
            onConfirm: () => {
              requestMyInfo().then(v=>{
                setMyInfo(v.info);
              });
              requestMyGroupVaultList().then(v=>{
                setMyVaults(v.list || []);
              });
            }
          });
        }else if(v.result == 0){
          openDialog({
            title: t('정산 실패'),
            text: t('정산에 실패했습니다. 관리자에게 문의해주세요.'),
            onConfirm: () => {
            }
          });
        }
      });
    }
  }, [myVaults]);

  return <Wrapper>
    <MyInfoContainer>
      <Title>
        <img src="/images/app_logo.svg"/>
      </Title>
      {
        myInfo ? <>
          <ProfileWrapper>
            <div>
              <ProfileImage src={`/images/profile/${myInfo.profileImg}.png`} onClick={() => setShowProfile(true)}/>
              <UserName>{myInfo.nickname}</UserName>
            </div>
            <MyPoint>
              <img src="/images/ic_credit.svg"/>
              <PointText>{myInfo.money.toLocaleString()}</PointText>
            </MyPoint>
          </ProfileWrapper>
        </> : <>
          Loading...
        </>
      }
    </MyInfoContainer>
    <Menu current={menu} onClickMenu={handleClickMenu} tournamentGroups={tournamentGroups?.list||[]}/>
    <Content>
      {
        <MenuTitle>
          {
            menu === MENU_CASH && "CASH GAME"
          }
          {
            menu === MENU_TOURNAMENT && "TOURNAMENT"
          }
          {
            menu === MENU_NOTICE && t("공지사항")
          }
          {
            menu !== MENU_SETTING && (
              <RefreshButton onClick={() => handleRefresh(menu)}>
                <img src="/images/ic_refresh_list.svg"/>{t("새로고침")}
              </RefreshButton>
            )
          }
        </MenuTitle>
      }
      {
        menu === MENU_CASH && (
          <Grid>
            {
              ringGroups?.list.map(e => (
                <RingItem key={e.groupId}>
                  <img onClick={() => handleClickJoin(e.groupId)} className="cover" src={`/images/ring_thumb_${bigBlindToGameStyle(e.data.blind[1])}.png`}/>
                  <RingInfo>
                    <div className="row">
                      <span>BUY IN</span>
                      {e.data.minBuyin.toLocaleString()} ~ {e.data.maxBuyin.toLocaleString()}
                    </div>
                    {
                      e.data.isAnte && (
                        <div className="row">
                          <span>{t('앤티')}</span>
                          {e.data.ante.toLocaleString()}
                          {
                            e.data.isStraddle && <>
                              <span>{t('스트래들')}</span> {t('있음')}
                            </>
                          }
                        </div>
                      )
                    }
                    {/*<div className="row">*/}
                    {/*  <span>{t('레이크')}</span> {e.data.rakeType == RAKE_TYPE.NONE ? t("없음") : (e.data.rakeType == RAKE_TYPE.HAND_RAKE ? t("핸드레이크") : t("팟레이크"))}{e.data.rakeType != RAKE_TYPE.NONE && (e.data.rakeType == RAKE_TYPE.HAND_RAKE ? `, ${e.data.rake.toLocaleString()}` : `, ${e.data.rake}%`)}*/}
                    {/*</div>*/}
                    <div className="row">
                      <span>SB/BB</span>
                      {e.data.blind[0].toLocaleString()} / {e.data.blind[1].toLocaleString()}
                    </div>
                    <div className="row" style={{display:"flex"}}>
                      <PlayerCount count={e.totalPlayerCount}/>
                      <PlayerCount count={e.tableCount} img='/images/table_count_bg.svg?v=1'/>
                    </div>
                    <JoinRoomButtons>
                      {!myVaults.find(v=>v.groupId == e.groupId) && <div className='empty' />}
                      { myVaults.find(v=>v.groupId == e.groupId) && <div className='adjust' onClick={()=>onClickCashOut(e.groupId)}>{t("정산하기")}</div>}
                    </JoinRoomButtons>
                  </RingInfo>

                  {/*<RingStatus maxTableMember={e.data.maxTableMember}>*/}
                  {/*  <TableCount className="table-count">*/}
                  {/*    <div className="max-seat">{e.data.maxTableMember}</div>*/}
                  {/*    {e.tableCount}*/}
                  {/*  </TableCount>*/}
                  {/*  <PlayerCount count={e.totalPlayerCount}/>*/}
                  {/*</RingStatus>*/}
                </RingItem>
              ))
            }
          </Grid>
        )
      }
      {
        (menu === MENU_TOURNAMENT && orientation === 'portrait') && (
          <ul>
            {
              tournamentGroups?.list.map((group, i) => (
                <Link key={group.groupId} to={`/tournament/${group.groupId}`}>
                  <TournamentItem group={group}/>
                </Link>
              ))
            }
            {
              tournamentGroups?.list.length === 0 && (
                <EmptyView>
                  <img src="/images/ic_tournament_empty.svg"/>
                  {t('아직 열려있는 토너먼트가 없습니다.')}
                </EmptyView>
              )
            }
          </ul>
        )
      }
      {
        (menu === MENU_TOURNAMENT && orientation === 'landscape') && (
          <LobbyTable>
            <div className="table-head lobby-tournament">
              <div className="table-row">
                <div>{t('상태')}</div>
                <div>{t('이벤트이름')}</div>
                <div>{t('시작')}</div>
                <div>{t('바이인')}</div>
                <div>{t('인원')}</div>
                <div>{t('상금')}</div>
              </div>
            </div>
            <div className="table-body lobby-tournament">
              
              {
                tournamentGroups?.list.filter(v=>{
                  const startedAt = parseDatetime(v.data.startedAt);
                  const isStarted = moment().utc().isSameOrAfter(startedAt);

                  return isStarted && !v.isEnd;
                }).map((group, i) => (
                  <TournamentItem
                    key={group.groupId}
                    className="table-row"
                    group={group}
                    landscape
                    onClick={handleClickTournament}
                  />
                ))
              }

              {
                tournamentGroups?.list.filter(v=>{
                  const startedAt = parseDatetime(v.data.startedAt);
                  const isStarted = moment().utc().isSameOrAfter(startedAt);

                  return !isStarted || v.isEnd;
                }).map((group, i) => (
                  <TournamentItem
                    key={group.groupId}
                    className="table-row"
                    group={group}
                    landscape
                    onClick={handleClickTournament}
                  />
                ))
              }

              {
                tournamentGroups?.list.length === 0 && (
                  <EmptyView>
                    <img src="/images/ic_tournament_empty.svg"/>
                    {t('아직 열려있는 토너먼트가 없습니다.')}
                  </EmptyView>
                )
              }
            </div>
          </LobbyTable>
        )
      }
      {
        menu === MENU_NOTICE && (
          <Grid>
            {
              notices?.list.map((x: any) => {
                const r = JSON.parse(localStorage.getItem('notices') || '[]');
                const c = x.noticeId <= 30 || r.includes(x.noticeId);
                return <Link key={x.noticeId} to={`/notice/${x.noticeId}`}>
                  <RingItem>
                    <img className="cover" src={x.thumb}/>
                    <NoticeTitle>{x.title}</NoticeTitle>
                    {!c ? <>
                      <Ripple delayed={0}/>
                      <Ripple delayed={0.7}/>
                      <Ripple delayed={1.4}/>
                    </> : <></>}
                  </RingItem>
                </Link>
              })
            }
            {
              notices?.list.length === 0 && (
                <EmptyView>
                  <img src="/images/ic_event_empty.svg"/>
                  {t('아직 열려있는 이벤트가 없습니다.')}
                </EmptyView>
              )
            }
          </Grid>
        )
      }
      {
        menu === MENU_SETTING && <>
          <Setting/>
        </>
      }
    </Content>
    {
      (showProfile && myInfo?.userId) && (
        <ModalContainer show>
          <ProfileModal userId={myInfo.userId} onClose={() => setShowProfile(false)}/>
        </ModalContainer>
      )
    }
    {
      <RightDrawer opened={selectedGroupId !== undefined} noSwipe onClose={() => setSelectedGroupId(undefined)}>
        {
          selectedGroupId !== undefined && (
            <TournamentDetail groupId={selectedGroupId} onClose={() => setSelectedGroupId(undefined)}/>
          )
        }
      </RightDrawer>
    }
  </Wrapper>;
};
