import styled from "styled-components";

const Wrapper = styled.div<{
  size?: number,
  emoticonId: number,
  frames: number
}>`
  width: ${p => p.size || 48}px;
  height: ${p => p.size || 48}px;

  background-image: url(/images/emoticon/${p => p.emoticonId}.png);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;

  @keyframes sprite-anim-${p => `${p.size}-${p.frames}`} {
    100% {
      background-position: -${p => (p.size || 48) * p.frames}px;
    }
  }

  animation ${p => `sprite-anim-${p.size}-${p.frames} 1s steps(${p.frames}) infinite`}
`;

const FRAMES_BY_ID = [0, 10, 18, 18, 16, 20, 18, 12, 12, 10, 8, 8, 18, 20, 32, 8, 10];

function Emoticon(
  {
    id,
    size
  }: {
    id: number,
    size?: number
  }
) {
  return <Wrapper size={size} emoticonId={id} frames={FRAMES_BY_ID[id]}/>
}

export default Emoticon;