import React from "react";
import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  color: #2F99FB;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;

  > img {
    margin-right: 2px;
    height: 13px;
    vertical-align: text-top;
  }

  @media ${MEDIA_DESKTOP} {
    font-size: 14px;
    font-weight: 600;
    
    > img {
      height: 16px;
      margin-right: 4px;
    }
  }
`;

function PlayerCount({count, max, img}: {count: number, max?: number, img?: string}) {
  return <Wrapper className="player-count">
    <img src={img||"/images/ic_game_blind_players.svg"}/>
    {count}{max ? `/${max}` : ''}
  </Wrapper>;
}

export default PlayerCount;