import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {handHistoryModel} from "../../dataset";
import {requestGetHandHistory} from "../../api";
import HistoryPlayer from "./HistoryPlayer";
import Flex from "../common/Flex";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import HistoryPagination from "./HistoryPagination";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #101012;
  border-left: 1px solid #43546C;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  @media ${MEDIA_DESKTOP} {
    height: 72px;
  }

  > .back {
    width: 48px;
    height: 48px;
    padding: 12px;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
      height: 72px;
      padding: 24px;
    }
  }

  > .title {
    flex: 1;
    font-size: 16px !important;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .gap {
    width: 48px;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 12px;

  @media ${MEDIA_DESKTOP} {
    padding: 0;
  }
`;

const GameCards = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: #17171B;
  display: flex;
  align-items: center;
  padding: 8px 12px;

  @media ${MEDIA_DESKTOP} {
    background: transparent;
    margin-top: 10px;
  }

  > .title {
    font-size: 10px;
    font-weight: 500;
    margin-right: 20px;
  }

  > .cards {
    flex: 1;
    display: flex;
    gap: 6px;

    > img {
      width: 24px;
      height: 32px;

      @media ${MEDIA_DESKTOP} {
        width: 42px;
        height: 56px;
      }
    }
  }
`;

const DefaultView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_DESKTOP} {
    height: 346px;
  }

  .table {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > .seats {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > .dealer {
      position: absolute;
      width: 16px;
      left: -100%;
      top: -100%;
      z-index: 2;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.40));
    }

    > .pot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      img {
        width: 48px;
      }

      .sidepot {
        width: 69px;
        padding: 2px 8px;
        font-size: 10px;
        transform: scale(0.9);
        font-weight: 600;
        border-radius: 15px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
      }

      .box {
        width: 95px;
        padding: 4px;
        font-size: 10px;
        font-weight: 500;
        border-radius: 4px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;

        > div:last-child {
          color: #2F99FB;
          font-weight: 700;
        }

        @media ${MEDIA_DESKTOP} {
          font-size: 14px;

          > div:first-child {
            display: none;
          }

          > div:last-child {
            color: #FFF;
          }
        }
      }
    }
  }
`;

const NoHistory = styled(DefaultView)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailView = styled.div`
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    padding: 12px 16px;
  }

  > .round-item {
    font-size: 10px;

    @media ${MEDIA_DESKTOP} {
      display: flex;
      gap: 12px;
      padding: 12px 0;
      border-bottom: 1px solid #2D2D2D;
      font-size: 12px;
      align-items: flex-start;

      > div {
        flex: 1;
      }
    }

    > .community {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 0;
      border-top: 1px solid #2D2D2D;
      border-bottom: 1px solid #2D2D2D;

      @media ${MEDIA_DESKTOP} {
        border: none;
        font-size: 12px;
      }

      > div {
        display: inline-flex;
        align-items: center;
      }

      > .round-name {
        width: 34px;
        font-weight: 500;

        @media ${MEDIA_DESKTOP} {
          width: 44px;
        }

      }

      > .round-cards {
        display: flex;
        gap: 4px;

        > img {
          width: 15px;
          height: 20px;

          @media ${MEDIA_DESKTOP} {
            width: 24px;
            height: 32px;
          }

        }
      }

      > .count {
        > img {
          width: 12px;
          height: 12px;
          margin-right: 2px;

          @media ${MEDIA_DESKTOP} {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }

        > &:last-child {
          opacity: 0.7;
        }
      }
    }

    > .bets {
      display: flex;
      flex-direction: column;
      padding: 12px 0;
      gap: 8px;

      > div {
        display: flex;
        align-items: center;
        gap: 4px;

        > .position {
          width: 24px;
          padding: 2px 4px;
          font-size: 9px;
          font-weight: 500;
          text-align: center;
          border-radius: 2px;
          background: #17171B;
          overflow: hidden;

          @media ${MEDIA_DESKTOP} {
            width: 36px;
            padding: 6px 4px;
            font-size: 12px;
          }
        }

        > .nickname {
          font-size: 12px;

          @media ${MEDIA_DESKTOP} {
            font-size: 14px;
          }
        }

        > .action {
          width: 40px;
          padding: 2px 4px;
          font-size: 9px;
          font-weight: 500;
          text-align: center;
          border-radius: 2px;
          background: #17171B;

          @media ${MEDIA_DESKTOP} {
            width: 48px;
            padding: 6px 4px;
            margin-left: 8px;
            font-size: 12px;
          }

          &[data-action="FOLD"] {
            color: #FFF;
            background: rgba(255, 255, 255, 0.20);
          }

          &[data-action="SB"], &[data-action="BB"], &[data-action="STR"] {
            color: #61FF84;
            background: rgba(97, 255, 132, 0.20);
          }

          &[data-action="CHECK"], &[data-action="CALL"] {
            color: #FFE895;
            background: rgba(255, 232, 149, 0.20);
          }

          &[data-action="BET"], &[data-action="RAISE"] {
            color: #5DD3BC;
            background: rgba(93, 211, 188, 0.20);
          }

          &[data-action="ALLIN"] {
            color: #469BFF;
            background: rgba(70, 155, 255, 0.20);
          }
        }
      }
    }
  }
`;

const Border = styled.div`
  width: 100%;
  min-height: 1px;
  background: #2D2D2D;
`;

const Footer = styled.div`
  width: 100%;
  background: #17171B;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
`;


const ChangeViewButton = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
`;

function GameHistory(
  {
    groupId,
    roomId,
    handNumber,
    maxTableMember,
    onClose,
    currentRound,
    isOpen
  }: {
    groupId: number;
    roomId: number;
    handNumber: number;
    maxTableMember: number;
    onClose?: () => void;
    currentRound?: string;
    isOpen: boolean;
  }
) {
  const {t} = useTranslation();
  const orientation = useScreenOrientation();
  const seatsRef = useRef<HTMLDivElement>(null);
  const dealerButtonRef = useRef<HTMLImageElement>(null);
  const gameOption = useRecoilValue(gameOptionState);
  const [currentPage, setCurrentPage] = useState<number>(handNumber - 1);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [handHistory, setHandHistory] = useState<handHistoryModel[]>([]);

  const endPage = useMemo(() => {
    if(handNumber>0 && (currentRound === undefined || currentRound === null)){
      return handNumber
    }else{
      return handNumber - 1;
    }
  }, [handNumber, currentRound]);

  useEffect(()=>{
    setCurrentPage(endPage)
  }, [isOpen])

  const lastGameData = useMemo(() => {
    if (handHistory.length === 0) {
      return;
    }

    return handHistory.slice(-1)[0].lastGameData;
  }, [handHistory]);

  const dealerSeat = useMemo<number>(() => {
    let player;
    player = handHistory.find(x => x.position === 'dealer');
    if (!player) {
      // 딜러 포지션이 없으면 1:1 상황으로 판단하고 bb를 딜러로 설정한다.
      player = handHistory.find(x => x.position === 'bb');
    }

    return !player ? -1 : player.seat;
  }, [handHistory]);

  const players = useMemo(() => {
    const arr: {
      userId: number;
      seat: number;
      nickname: string;
      agencyName: string;
      profileImg: string;
      isFold: boolean;
    }[] = [];

    for (let row of handHistory) {
      const player = arr.find(x => x.userId === row.userId);
      if (player) {
        player.isFold = row.fold;
      } else {
        arr.push({
          userId: row.userId,
          seat: row.seat,
          nickname: row.nickname,
          agencyName: row.agencyName,
          profileImg: row.profileImg,
          isFold: row.fold
        });
      }
    }

    return arr;
  }, [handHistory]);

  const betHistory = useMemo(() => {
    if (handHistory.length === 0 || !lastGameData) {
      return [];
    }

    const playersPosition: {
      [userId: number]: string
    } = {};
    const arr: {
      round: number,
      cards: number[],
      players: number[],
      pot: number,
      bets: any[]
    }[] = [];
    const usersPosition: any = {}
    for (let row of handHistory) {
      const round = row.round;
      if(round === 0){
        let position = row.position
        if (position === 'dealer') {
          position = 'BT';
        }
        usersPosition[row.userId] = position.toUpperCase()
      }
      if (!arr[round]) {
        arr[round] = {
          round: round,
          cards: lastGameData.communityCards.slice(0, round + 2),
          players: [],
          pot: 0,
          bets: [],
        };
      }

      if (arr[round].players.indexOf(row.userId) === -1) {
        arr[round].players.push(row.userId);
      }

      if (!playersPosition[row.userId]) {
        let position = row.position;
        if (position === 'dealer') {
          position = 'BT';
        }
        playersPosition[row.userId] = position.toUpperCase();
      }

      arr[round].pot += row.bet;
      arr[round].bets.push({
        betId: row.betId,
        userId: row.userId,
        nickname: row.nickname,
        bet: row.bet,
        position: usersPosition[row.userId],
        action: ['', 'CHECK', 'CALL', 'FOLD', 'RAISE', 'ALLIN', 'BET', 'SB', 'BB', 'STR'][row.type]
      });
    }

    return arr;
  }, [handHistory, lastGameData]);

  const totalPot = useMemo(() => {
    if (!lastGameData) {
      return 0;
    }

    return lastGameData.pots.reduce((a, v) => a + v.amount, 0);
  }, [lastGameData]);

  const arrangeLayout = useCallback(() => {
    if (!seatsRef.current) {
      return;
    }

    function position(arr: any, idx: number, left: number, top: number) {
      try {
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const isPortrait = orientation === 'portrait';
    const seatRects = isPortrait ? [
      [0.5, 0.95],
      [0.03, 0.77],
      [0.03, 0.53],
      [0.03, 0.3],
      [0.32, 0.09],
      [0.96, 0.3],
      [0.96, 0.53],
      [0.96, 0.77],
      [0.7, 0.09]
    ] : [
      [0.28, 0.81],
      [0.11, 0.62],
      [0.11, 0.36],
      [0.28, 0.2],
      [0.5, 0.2],
      [0.72, 0.2],
      [0.89, 0.36],
      [0.89, 0.62],
      [0.69, 0.81]
    ];

    const buttonRects = isPortrait ? [
      [0.5, 0.90],
      [0.2, 0.77],
      [0.2, 0.53],
      [0.2, 0.3],
      [0.34, 0.21],
      [0.66, 0.21],
      [0.8, 0.3],
      [0.8, 0.53],
      [0.8, 0.77]
    ] : [
      [0.28, 0.63],
      [0.23, 0.58],
      [0.22, 0.43],
      [0.31, 0.34],
      [0.45, 0.34],
      [0.69, 0.34],
      [0.78, 0.43],
      [0.78, 0.58],
      [0.69, 0.63]
    ];

    let deleteSeatIndices: number[] = [];
    if (maxTableMember === 5) {
      deleteSeatIndices = isPortrait ? [6, 5, 4, 3] : [8, 7, 6, 5];
    } else if (maxTableMember === 6) {
      deleteSeatIndices = isPortrait ? [5, 4, 3] : [7, 6, 5];
    } else if (maxTableMember === 8) {
      if (isPortrait) {
        // 8자리일 때는 모바일에서만 최상단 한 자리 삭제
        seatRects[4][0] = 0.5;
        buttonRects[4][0] = 0.5;
      }
    }

    for (let seatIndex of deleteSeatIndices) {
      seatRects.splice(seatIndex, 1);
      buttonRects.splice(seatIndex, 1);
    }

    const width = seatsRef.current.offsetWidth;
    const height = seatsRef.current.offsetHeight;
    const left = seatsRef.current.offsetLeft;
    const top = seatsRef.current.offsetTop;

    const seats = seatsRef.current.querySelectorAll('.history-player');
    for (let i = 0; i < maxTableMember; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1]);
    }

    if (dealerButtonRef.current && dealerSeat !== -1) {
      const dealerButton = dealerButtonRef.current;
      position([dealerButton], 0, left + width * buttonRects[dealerSeat][0], top + height * buttonRects[dealerSeat][1]);
    }
  }, [players, dealerSeat]);

  const toggleView = useCallback(() => {
    setShowDetail((b) => !b);
  }, []);

  const handlePrev = useCallback(() => {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const handleLatest = useCallback(() => {
    setCurrentPage(endPage);
  }, [endPage]);

  const handleNext = useCallback(() => {
    if (currentPage + 1 <= endPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, endPage]);

  useEffect(() => {
    if (currentPage < 0) {
      return;
    }

    requestGetHandHistory(groupId, roomId, currentPage).then(({result}) => {
      const sorted = result.sort((a: any, b: any) => a.betId - b.betId);
      setHandHistory(sorted);
    });
  }, [roomId, currentPage]);

  useEffect(() => {
    if (currentPage < 1 && handNumber >= 2) {
      setCurrentPage(1);
    }
  }, [handNumber, currentPage]);

  useEffect(() => {
    arrangeLayout();
  }, [arrangeLayout]);


  return <Wrapper>
    <Header>
      {
        orientation === 'portrait' ? <>
          <img className="back" src="/images/ic_header_back.svg" onClick={onClose}/>
          <div className="title">{t('게임기록')}</div>
          <div className="gap"/>
        </> : <>
          <div className="gap"/>
          {
            currentPage > 0 ? (
              <div className="title">
                <HistoryPagination
                  current={currentPage}
                  total={endPage}
                  onClickPrev={handlePrev}
                  onClickNext={handleNext}
                  onClickLatest={handleLatest}
                />
              </div>
            ) : (
              <Flex />
            )
          }
          <img className="back" src="/images/ic_header_close.svg" onClick={onClose}/>
        </>
      }
    </Header>
    {
      handNumber > 1 ? <>
        <Content>
          {
            orientation === 'portrait' && (
              <GameCards>
                <div className="title">{t('{{n}}번째 게임', {n: currentPage})}</div>
                <div className="cards">
                  {
                    (lastGameData?.communityCards ?? []).map((card, i) => (
                      <img key={i} src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
                    ))
                  }
                </div>
              </GameCards>
            )
          }
          {
            (orientation === 'landscape' || !showDetail) && (
              <DefaultView>
                <div className="table">
                  <img onLoad={arrangeLayout}
                       src={`/images/table/history_${orientation}_blue.svg`}/>
                  <div ref={seatsRef} className="seats">
                    {
                      Array.from({length: maxTableMember}).map((_, i) => {
                        const player = players.find(x => x.seat == i);
                        if (!player) {
                          return <div key={i} className="history-player" data-seat={i}/>;
                        }
                        const playerGameData = (lastGameData?.changeStackSizeList ?? []).find(x => x.userId === player.userId);
                        const stackSize = playerGameData?.stackSize || 0;
                        const prevStackSize = playerGameData?.prevStackSize || 0;

                        const winner = lastGameData?.winners.find(x => x.userId === player.userId);
                        const isWinner = winner !== undefined;
                        const prize = isWinner ? winner.amount : 0;
                        const cards = lastGameData?.cards.find(x => x.userId === player.userId);
                        
                        return <HistoryPlayer
                          key={i}
                          userId={player.userId}
                          seat={player.seat}
                          nickname={player.nickname}
                          profileImg={player.profileImg}
                          folded={player.isFold}
                          winner={isWinner}
                          prize={prize}
                          cards={cards?.cards || []}
                          agencyName={player.agencyName}
                          changeStackSize={stackSize - prevStackSize}
                        />;
                      })
                    }
                  </div>
                  <img ref={dealerButtonRef} className="dealer" src="/images/dealer_button.svg"/>
                  {
                    (lastGameData && lastGameData.pots) && (
                      <div className="pot">
                        <img src="/images/game_pot_chips.svg"/>
                        {
                          orientation === 'portrait' && <>
                            {
                              lastGameData.pots.map((pot, i) => (
                                <div key={i} className="sidepot">{pot.amount.toLocaleString()}</div>
                              ))
                            }
                          </>
                        }
                        <div className="box">
                          <div>POT</div>
                          <div>{totalPot.toLocaleString()}</div>
                        </div>
                        {
                          orientation === 'landscape' && (
                            <GameCards>
                              <div className="cards">
                                {
                                  (lastGameData?.communityCards ?? []).map((card, i) => (
                                    <img key={i} src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
                                  ))
                                }
                              </div>
                            </GameCards>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </DefaultView>
            )
          }
          {
            (orientation === 'landscape' || showDetail) && (
              <DetailView>
                {
                  betHistory.map((x, i) => (
                    <div key={i} className="round-item">
                      <div className="community">
                        <div className="round-name">{[t('프리플랍'), t('플랍'), t('턴'), t('리버')][x.round]}</div>
                        <div className="round-cards">
                          {
                            x.round === 0 ? <>
                              <img src={`/images/card_back.svg`}/>
                              <img src={`/images/card_back.svg`}/>
                            </> : <>
                              {
                                x.cards.map((card, i) => (
                                  <img key={i} src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
                                ))
                              }
                            </>
                          }
                        </div>
                        <Flex/>
                        <div className="count">
                          <img src="/images/ic_player_white.svg"/>
                          <div>{x.players.length.toLocaleString()}</div>
                        </div>
                        <div className="count">
                          <img src="/images/coin_1.svg"/>
                          <div>{x.pot.toLocaleString()}</div>
                        </div>
                      </div>
                      <div className="bets">
                        {
                          x.bets.filter(_ => _.action !== undefined ).map((bet) => (
                            <div key={bet.betId}>
                              <div className="position">{bet.position}</div>
                              <div className="nickname">{bet.nickname}</div>
                              <Flex/>
                              <div className="amount">{bet.bet.toLocaleString()}</div>
                              <div className="action" data-action={bet.action}>{bet.action}</div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
              </DetailView>
            )
          }
        </Content>
        {
          orientation === 'portrait' && (
            <Footer>
              <div>
                <ChangeViewButton onClick={toggleView}>
                  {showDetail ? t('기본보기') : t('상세보기')}
                </ChangeViewButton>
              </div>
              <HistoryPagination
                current={currentPage}
                total={endPage}
                onClickPrev={handlePrev}
                onClickNext={handleNext}
                onClickLatest={handleLatest}
              />
            </Footer>
          )
        }
      </> : <>
        <Content>
          <NoHistory>{t('진행된 게임이 없습니다.')}</NoHistory>
        </Content>
      </>
    }
  </Wrapper>;
}

export default GameHistory;
