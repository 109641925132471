//auto gen
import { io } from "socket.io-client";
import { useEffect, useState } from "react";
import i18n from "../utils/i18n";

// export all server api for preventing duplicate api name, etc
export { default as useSocketLogOut } from "./game_logOut"
export { default as useSocketCreateRoomAdmin } from "./game_createRoomAdmin"
export { default as useSocketCreateTournamentAdmin } from "./game_createTournamentAdmin"
export { default as useSocketGetHandCuttingList } from "./game_getHandCuttingList"
export { default as useSocketRequestEmoji } from "./game_requestEmoji"
export { default as useSocketRequestCardOpen } from "./game_requestCardOpen"
export { default as useSocketGetNoticeDetail } from "./game_getNoticeDetail"
export { default as useSocketGetNoticelist } from "./game_getNoticelist"
export { default as useSocketGetWinnerList } from "./game_getWinnerList"
export { default as useSocketGetTournamentBuyinCount } from "./game_getTournamentBuyinCount"
export { default as useSocketCancelJoinTournament } from "./game_cancelJoinTournament"
export { default as useSocketToggleEmoticonBan } from "./game_toggleEmoticonBan"
export { default as useSocketRegisterOrJoinTournament } from "./game_registerOrJoinTournament"
export { default as useSocketRebuyinTournament } from "./game_rebuyinTournament"
export { default as useSocketReport } from "./game_report"
export { default as useSocketJoinLobby } from "./game_joinLobby"
export { default as useSocketLeaveLobby } from "./game_leaveLobby"
export { default as useSocketRingGroupList } from "./game_ringGroupList"
export { default as useSocketCanCashoutVault } from "./game_canCashoutVault"
export { default as useSocketDoCashoutVault } from "./game_doCashoutVault"
export { default as useSocketMyGroupVaultList } from "./game_myGroupVaultList"
export { default as useSocketTournamentGroupList } from "./game_tournamentGroupList"
export { default as useSocketGetMyTournamentRank } from "./game_getMyTournamentRank"
export { default as useSocketGetTournamentPrize } from "./game_getTournamentPrize"
export { default as useSocketGetTournamentUserRanks } from "./game_getTournamentUserRanks"
export { default as useSocketGetTournamentGroup } from "./game_getTournamentGroup"
export { default as useSocketCurrentRooms } from "./game_currentRooms"
export { default as useSocketCurrentRoom } from "./game_currentRoom"
export { default as useSocketJoinRoom } from "./game_joinRoom"
export { default as useSocketWillShutdown } from "./game_willShutdown"
export { default as useSocketGetUserProfile } from "./game_getUserProfile"
export { default as useSocketModifyUserProfile } from "./game_modifyUserProfile"
export { default as useSocketJoinRingGroup } from "./game_joinRingGroup"
export { default as useSocketSetBlindWait } from "./game_setBlindWait"
export { default as useSocketLeaveRoomAndCleanup } from "./game_leaveRoomAndCleanup"
export { default as useSocketLeaveRoom } from "./game_leaveRoom"
export { default as useSocketTryBet } from "./game_tryBet"
export { default as useSocketRoomInfo } from "./game_roomInfo"
export { default as useSocketMyStatusInRoom } from "./game_myStatusInRoom"
export { default as useSocketGetRoomGroupId } from "./game_getRoomGroupId"
export { default as useSocketPlayersBetPot } from "./game_playersBetPot"
export { default as useSocketMyCards } from "./game_myCards"
export { default as useSocketBuyin } from "./game_buyin"
export { default as useSocketGetMyHandHistoryCount } from "./game_getMyHandHistoryCount"
export { default as useSocketGetMyHandHistory } from "./game_getMyHandHistory"
export { default as useSocketGetHandHistory } from "./game_getHandHistory"
export { default as useSocketIsServerLive } from "./game_isServerLive"
export { default as useSocketLogin } from "./ping_login"
export { default as useSocketMyInfo } from "./ping_myInfo"
export { default as useSocketDirectLinkLogin } from "./ping_directLinkLogin"
export { default as useSocketTping } from "./ping_tping"
export { default as useSocketPping } from "./ping_pping"
export { default as useSocketNoticeIds } from "./ping_noticeIds"
export { request as requestLogOut } from "./game_logOut"
export { request as requestCreateRoomAdmin } from "./game_createRoomAdmin"
export { request as requestCreateTournamentAdmin } from "./game_createTournamentAdmin"
export { request as requestGetHandCuttingList } from "./game_getHandCuttingList"
export { request as requestRequestEmoji } from "./game_requestEmoji"
export { request as requestRequestCardOpen } from "./game_requestCardOpen"
export { request as requestGetNoticeDetail } from "./game_getNoticeDetail"
export { request as requestGetNoticelist } from "./game_getNoticelist"
export { request as requestGetWinnerList } from "./game_getWinnerList"
export { request as requestGetTournamentBuyinCount } from "./game_getTournamentBuyinCount"
export { request as requestCancelJoinTournament } from "./game_cancelJoinTournament"
export { request as requestToggleEmoticonBan } from "./game_toggleEmoticonBan"
export { request as requestRegisterOrJoinTournament } from "./game_registerOrJoinTournament"
export { request as requestRebuyinTournament } from "./game_rebuyinTournament"
export { request as requestReport } from "./game_report"
export { request as requestJoinLobby } from "./game_joinLobby"
export { request as requestLeaveLobby } from "./game_leaveLobby"
export { request as requestRingGroupList } from "./game_ringGroupList"
export { request as requestCanCashoutVault } from "./game_canCashoutVault"
export { request as requestDoCashoutVault } from "./game_doCashoutVault"
export { request as requestMyGroupVaultList } from "./game_myGroupVaultList"
export { request as requestTournamentGroupList } from "./game_tournamentGroupList"
export { request as requestGetMyTournamentRank } from "./game_getMyTournamentRank"
export { request as requestGetTournamentPrize } from "./game_getTournamentPrize"
export { request as requestGetTournamentUserRanks } from "./game_getTournamentUserRanks"
export { request as requestGetTournamentGroup } from "./game_getTournamentGroup"
export { request as requestCurrentRooms } from "./game_currentRooms"
export { request as requestCurrentRoom } from "./game_currentRoom"
export { request as requestJoinRoom } from "./game_joinRoom"
export { request as requestWillShutdown } from "./game_willShutdown"
export { request as requestGetUserProfile } from "./game_getUserProfile"
export { request as requestModifyUserProfile } from "./game_modifyUserProfile"
export { request as requestJoinRingGroup } from "./game_joinRingGroup"
export { request as requestSetBlindWait } from "./game_setBlindWait"
export { request as requestLeaveRoomAndCleanup } from "./game_leaveRoomAndCleanup"
export { request as requestLeaveRoom } from "./game_leaveRoom"
export { request as requestTryBet } from "./game_tryBet"
export { request as requestRoomInfo } from "./game_roomInfo"
export { request as requestMyStatusInRoom } from "./game_myStatusInRoom"
export { request as requestGetRoomGroupId } from "./game_getRoomGroupId"
export { request as requestPlayersBetPot } from "./game_playersBetPot"
export { request as requestMyCards } from "./game_myCards"
export { request as requestBuyin } from "./game_buyin"
export { request as requestGetMyHandHistoryCount } from "./game_getMyHandHistoryCount"
export { request as requestGetMyHandHistory } from "./game_getMyHandHistory"
export { request as requestGetHandHistory } from "./game_getHandHistory"
export { request as requestIsServerLive } from "./game_isServerLive"
export { request as requestLogin } from "./ping_login"
export { request as requestMyInfo } from "./ping_myInfo"
export { request as requestDirectLinkLogin } from "./ping_directLinkLogin"
export { request as requestTping } from "./ping_tping"
export { request as requestPping } from "./ping_pping"
export { request as requestNoticeIds } from "./ping_noticeIds"

const API_HOST = process.env.REACT_APP_API_HOST as string;
export const USER_TOKEN = "user_token";
export const USER_ID = "user_id";
export const IS_EXERCISE = "isexercise"
export const IS_EXERCISE_STOP = "isexercisestop";
export const GID_TOKEN = "gid_token";

export const IS_EXERCISE_RID = "IS_EXERCISE_RID";

export const IS_EXERCISE_ENERGY = "IS_EXERCISE_ENERGY";

export const IS_EXERCISE_MAX_ENERGY = "IS_EXERCISE_MAX_ENERGY";

export const IS_EXERCISE_USER_ENERGY = "IS_EXERCISE_USER_ENERGY";

export const IS_EXERCISE_DISTANCE = "IS_EXERCISE_DISTANCE";

export const IS_EXERCISE_NOW_DISTANCE = "IS_EXERCISE_NOW_DISTANCE";

export const IS_EXERCISE_EARN_TOKEN = "IS_EXERCISE_EARN_TOKEN";

export const IS_EXERCISE_RANDOMSPOT = "IS_EXERCISE_RANDOMSPOT";

export const IS_EXERCISE_RANDOMSPOT_ENTERED = "IS_EXERCISE_RANDOMSPOT_ENTERED";

export const IS_EXERCISE_RANDOMSPOT_ENTER_COUNT = "IS_EXERCISE_RANDOMSPOT_ENTER_COUNT";

export let client = io(API_HOST, { transports: ["websocket"] });

export interface SockClientOpt{
  useStore: boolean;
  useKey?: string;
}

client.on("connect",()=>{
  console.log("connected server")
  setConnect(true, null);

  let token = localStorage.getItem(USER_TOKEN) as string;
  if(token){
      setLogin(token)
  }else{
      setConnect(true, false);
  }
})
client.on("disconnect", (reason:any) => {
  setConnect(false, null);
})
client.on("force_disconnect", (a:any)=>{
  alert(i18n.t("중복 로그인을 감지하여 로그인이 풀립니다"))
  setConnect(true,false);
})

const setLogin = async function(token:string){
  client.emit("set-token", token, async function(resp:any){
      console.log("resp.success", resp.success)
      if(resp.success == 1){
        setConnect(true, true);
      }else if(resp.success == 2){
        alert(i18n.t("동일한 계정으로 중복 접속을 할 수 없습니다"));
        setConnect(false, false);
      }else{
          setConnect(true, false);
      }
  })
}

const subscribeEvent:Array<(f:SockConnect)=>void> = []
function subscribe(func: (f:SockConnect)=>void) {
  subscribeEvent.push(func)
}
function unsubscribe(func: (f:SockConnect)=>void) {
  let idx = subscribeEvent.findIndex(e => e == func);
  if (idx >= 0) subscribeEvent.splice(idx, 1)
}

let _connect = false;
let _logined : boolean | null = null;
const setConnect = (c:boolean, l:boolean|null)=>{
  _connect = c;
  _logined = l;
  for (let evt of subscribeEvent) {
      evt({ isConnected:_connect, isLogin:_logined });
  }
}

export function connectionData(){
  return {
    connect:_connect,
    logined:_logined,
  }
}

interface SockConnect {
  isConnected:boolean, 
  isLogin:boolean|null
}

export const OnRecvData = async( data:any )=>{
if(data == null) return null;

if(data.code !== 0){
  console.error("error occurred")
}

if(!!data.login_token && _logined == false){
  setLogin(data.login_token); 
  localStorage.setItem(USER_TOKEN, data.login_token) 
}
return data;
}

export const useSocketIsConnect = ()=>{
  let [data,setData] = useState<SockConnect>({
      isConnected:_connect,
      isLogin:_logined,
  });

  useEffect(() => {
      const handleChange = (f:SockConnect)=>setData(f)

      subscribe(handleChange);
      return () => unsubscribe(handleChange);
  }, []);

  return [data]
}

export const Logout = ()=>{
localStorage.clear();
client.disconnect();
setTimeout(function(){
    client.connect();
},1000 * 1)
}
