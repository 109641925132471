import styled from "styled-components";
import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import { requestGetTournamentBuyinCount } from "../../api";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 14px 0;

  .message {
    line-height: 120%;
  }

  .gray{
    color: #8C8C8C;
  }

  .tip {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 8px;
    line-height: 1.5;
  }

  .border {
    width: 100%;
    min-height: 1px;
    background-color: #2D2D2D;
    margin: 16px 0;
  }

  span{
    color: #FFD600;
    font-weight: bold;
  }
`;

function TournamentRebuyinDialog(
  {
    groupId,
    maxRebuyin,
    buyinPrice,
    supplyChip,
    currentLevel,
    buyinCnt
  }: {
    buyinCnt:number,
    groupId:number,
    maxRebuyin:number,
    buyinPrice:number,
    supplyChip:number,
    currentLevel:number
  }
) {
  const {t} = useTranslation();

  return <Wrapper>
    <div className="border"/>
    <div className="message gray">
        {t("참가비를 지불하고 다시 참여하실 수 있습니다.")}<br/>
        {t("리바인 시 방은 재배정 될 수 있습니다.")}
    </div>
    <div className="border"/>
    <div className="message">
        {t("현재 레벨 : ")} <span>Lv.{currentLevel}</span>
    </div>
    <div className="border"/>
    <div className="message">
        {t("리바이인 비용 : ")} <span>{buyinPrice.toLocaleString()}</span>
    </div>
    <div className="border"/>
    <div className="message">
        {t("지급 받는 칩 : ")} <span>{supplyChip.toLocaleString()}</span>
    </div>
    <div className="border"/>
    <div className="message">
        {t("남은 바이인 횟수 : ")} <span> {maxRebuyin == -1 ? t("무제한") : (buyinCnt ? (maxRebuyin-buyinCnt).toLocaleString() : "Loading..")}</span>
    </div>
  </Wrapper>
}

export default TournamentRebuyinDialog