import styled from "styled-components";

const Wrapper = styled.div`
  width: 152px;
  height: 152px;
  
  img {
    width: 100%;
    height: 100%;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 10px 5px);
  }
`;

function Loading() {
  return <Wrapper>
    <img src="/images/effect/loading.png" />
  </Wrapper>
}

export default Loading;