import React, {useMemo} from "react";
import styled from "styled-components";
import {minimumChips} from "../../utils/chip";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  display: grid;
  position: relative;
  align-content: flex-end;
  justify-content: center;
  grid-template-columns: repeat(12, auto);
  grid-template-rows: repeat(2, auto);
`;

//  [1 2 3 4] [5 6 7 8] [9 10 11 12]
// [1 2 3] [4 5 6] [7 8 9] [10 11 12]
const Column = styled.div`
  position: relative;
  line-height: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.3));
  
  &:nth-of-type(1) {
    grid-column: 1 / span 3;
    grid-row: 2;
    z-index: 2;
  }

  &:nth-of-type(2) {
    grid-column: 4 / span 3;
    grid-row: 2;
    z-index: 2;
  }

  &:nth-of-type(3) {
    grid-column: 7 / span 3;
    grid-row: 2;
    z-index: 2;
    justify-content: flex-start;

    img {
      object-position: left;
    }
  }

  &:nth-of-type(4) {
    grid-column: 10 / span 3;
    grid-row: 2;
    z-index: 2;
  }

  &:nth-of-type(5) {
    grid-column: 5 / span 4;
    grid-row: 1;
    z-index: 1;
  }

  &:nth-of-type(6) {
    grid-column: 1 / span 4;
    grid-row: 1;
    z-index: 1;
    justify-content: flex-end;

    img {
      object-position: right;
    }
  }

  &:nth-of-type(7) {
    grid-column: 9 / span 4;
    grid-row: 1;
    z-index: 1;
    justify-content: flex-start;

    img {
      object-position: left;
    }
  }
  
  > img {
    width: 14px;
    height: 13px;
    z-index: 2;
    object-fit: contain;

    @media ${MEDIA_DESKTOP} {
      width: 20px;
      height: 19px;
    }
  }
  
  > img:not(:nth-child(1)) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
`;

const ChipImage = styled.img<{i: number}>`
  @keyframes chip-down {
    from {
      transform: translateY(-300%);
      opacity: 0;
    }
    
    to {
      opacity: 1;
    }
  }

  transform: translateY(${p => p.i * -11}%);
  animation: chip-down 0.25s ease-in-out ${p => p.i * 0.05}s backwards;
`;

const Chips = ({className, amount}: {
  className?: string,
  amount: number
}) => {
  const chips = useMemo(() => minimumChips(amount), [amount]);

  return <Wrapper className={['chips', className].join(' ')} data-amount={amount}>
    {
      chips.slice(0, 7).map(({size, amount}, i) => (
        <Column key={i}>
          {
            Array.from({length: amount}).map((_, i) => (
              <ChipImage key={`${size}${i}`} src={`/images/chip_${size}.png`} i={i} data-chip={size} data-top={i === amount-1}/>
            ))
          }
        </Column>
      ))
    }
  </Wrapper>
}

export default Chips;