import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { USER_TOKEN } from './api';
import './utils/i18n'

if( window.location.pathname.indexOf('/link') == 0 ){
  localStorage.removeItem(USER_TOKEN)
}
console.log(window.location.pathname, window.location.pathname.indexOf("/link"))

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.waitNativeLoad().then(()=>{
  root.render(
    <App />
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

var loadingbar = document.getElementById("loading-bar-modal");
if( loadingbar ){
  (loadingbar as any).remove();
}