import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{dark?: boolean; isMax?: boolean}>`
  width: 100%;
  min-height: 48px;
  max-height: 48px;
  background: url(${p => p.dark ? '/images/button_game_black.png' : '/images/button_game_red.png'});
  background-size: cover;
  
  ${p => p.isMax && `
    background: url(/images/button_game_orange.png) !important;
  `}
  
  @media ${MEDIA_DESKTOP} {
    min-height: 60px;
    max-height: 60px;
    background: url(/images/button_game_red_wide.png);

    ${p => p.isMax && `
      background: url(/images/button_game_orange_wide.png) !important;
    `}
  }
`;


function D3Button({className, dark, children, isMax}: {className?: string,isMax?:boolean; dark?: boolean, children?: any}) {
  return <Wrapper className={className} dark={dark} isMax={isMax}>
    {children}
  </Wrapper>
}

export default D3Button;
