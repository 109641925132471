const cards = Array.from({ length: 52 }, (_, i) => i).map((i) => {
  return {
    id: i,
    base64: '',
  }
})
export async function initCards() {
  try {
    const promises: Promise<void>[] = [];
    cards.forEach((card) => {{
      const promise = new Promise<void>(async (r) => {
        const res = await fetch(`/images/card/style-1/${card.id}.png`);
        const data = await res.arrayBuffer();
        const base64Data = Buffer.from(data).toString('base64');
        // 카드 객체에 Base64 데이터 저장
        card.base64 = `data:image/png;base64,${base64Data}`;
        r();
      })
      promises.push(promise);
    }})
    await Promise.allSettled(promises);
  } catch(err) {
    console.error("card loading error", err)
    throw err;
  }
}

export function getCardImage(id: number) {
  if(cards[id].base64){
    return cards[id].base64;
  }

  return `/images/card/style-1/${id}.png`
}

export function numberToCardData(num: number) {
  const tmpNumber = num % 13 + 1;
  let cardNumber = ''
  switch (tmpNumber) {
    case 1:
      cardNumber = '2';
      break;
    case 2:
      cardNumber = '3';
      break;
    case 3:
      cardNumber = '4';
      break;
    case 4:
      cardNumber = '5';
      break;
    case 5:
      cardNumber = '6';
      break;
    case 6:
      cardNumber = '7';
      break;
    case 7:
      cardNumber = '8';
      break;
    case 8:
      cardNumber = '9';
      break;
    case 9:
      cardNumber = '10';
      break;
    case 10:
      cardNumber = 'J';
      break;
    case 11:
      cardNumber = 'Q';
      break;
    case 12:
      cardNumber = 'K';
      break;
    case 13:
      cardNumber = 'A';
      break;
    default:
      cardNumber = '2';
      break;
  }

  let shape = Math.floor(num / 13);
  let image = '';
  switch (shape) {
    case 0:
      image = 'iconClover.png';
      break;
    case 1:
      image = 'iconDiamond.png';
      break;
    case 2:
      image = 'iconHeart.png';
      break;
    case 3:
      image = 'iconSpade.png';
      break;
    default:
      image = 'iconClover.png';
  }
  return {
    cardNumber,
    image,
  }
}
